import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { Container, Typography } from "@material-ui/core";
import api from "../../utils/API";
import Pagination from "@material-ui/lab/Pagination";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DeletedUser() {
  const history = useHistory();

  const [select, setSelection] = useState([]);
  const [userList, setUserList] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(10);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("message");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);
  useEffect(async () => {
    const result = await api.post("/getUserList", {
      is_delete: true,
      page: page,
      pagesize: 10,
    });
    setTotalRecords(result.data.total);
    console.log(result.data.data);
    setUserList(result.data.data);
  }, [page, refresh]);

  let newuserList = [];
  userList.map((data, index) => {
    newuserList.push({
      sn: index + 1 + (page - 1) * 10,
      id: data._id,
      ...data,
    });
  });

  const handleEdit = async (event, data) => {
    let ss = data.row._id;
    console.log(ss);
    const res = await api.post("/userStatus", { user_id: ss, restore: true });
    if (res.data.status == 1) {
      setType("success");
      setMessage(res.data.message);
      setOpen(true);
    } else {
      setType("error");
      setMessage(res.data.message);
      setOpen(true);
    }
    setRefresh(refresh + 1);
  };

  const columns = [
    // { field: '_id', headerName: 'ID', width:50 },
    { field: "sn", headerName: "SN#", width: 50 },
    { field: "fname", headerName: " Name", width: 130 },
    // { field: "lname", headerName: "Last Name", width: 100 },
    { field: "mobile", headerName: "Mobile", width: 130 },
    {
      field: "is_delete",
      headerName: "Status",
      width: 130,
      renderCell: (cellValues) => {
        return cellValues ? "Deleted" : "Restore";
      },
    },
    // { field: 'sex', headerName: 'Gender', width: 100 },
    { field: "city", headerName: "City ", width: 130 },
    { field: "country", headerName: "Country ", width: 130 },
    {
      field: "Action",
      width: 220,
      renderCell: (cellValues) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <Button
                variant="contained"
                color="success"
                onClick={(event) => {
                  handleEdit(event, cellValues);
                }}
              >
                Restore
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    // box-shadow: "5px 10px #888888",
    <Wrapper>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>

      <Container
        style={{
          backgroundColor: "#673147",
          color: "white",
          padding: 5,
          borderRadius: 10,
          boxShadow: "5px 10px 5px #888888",
        }}
      >
        <Typography align="center" variant="h4">
          List of All Blocked
        </Typography>
      </Container>
      <br />
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={newuserList}
          columns={columns}
          rowsPerPageOptions={[]}
        />
        <Pagination
          count={Math.ceil(totalRecords / 10)}
          onChange={(event, value) => setPage(value)}
        />
      </div>
    </Wrapper>
  );
}
