import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

import {
  Box,
  InputLabel,
  Button,
  Typography,
  Container,
  TextField,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import Select from "@mui/material/Select";
import api from "../../utils/API";
import { image_url } from "../../utils/API";
const NotificationList = () => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("message");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const history = useHistory();
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [refresh, setrefresh] = useState("abc");
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(10);

  useEffect(async () => {
    const res = await api.post("/getNotification", {
      page: page,
      pagesize: 10,
    });
    setData(res.data.data);
    setTotalRecords(res.data.total);

    console.log(res.data.data);
  }, [page, refresh]);

  let newData = [];
  data.map((item, index) => {
    newData.push({ sn: index + 1 + (page - 1) * 10, id: item._id, ...item });
  });

  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);

  const handleDelete = async (param, data) => {
    // console.log("delete", data.row);
    const res = await api.post("/deleteNotification", {
      notification_id: data.row._id,
    });
    if (res.data.status == 1) {
      setType("success");
      setMessage(res.data.message);
      setOpen(true);
      setTimeout(() => {
        window.location.reload(false);
      }, 800);
      setrefresh("2");
    } else {
      setType("error");
      setMessage(res.data.message);
      setOpen(true);
    }
  };

  const HandleResend = async (param, data) => {
    const res = await api.post("/resendNotification", {
      noti_id: data.row._id,
    });
    if (res.data.status == 1) {
      setType("success");
      setMessage(res.data.message);
      setOpen(true);
    } else {
      setType("error");
      setMessage(res.data.message);
      setOpen(true);
    }
  };
  const columns = [
    // { field: "_id", headerName: "ID", width: 20 },
    { field: "sn", headerName: "SN#", width: 20 },
    { field: "title", headerName: "Title ", width: 120 },
    {
      field: "image_url",
      headerName: "Image ",
      width: 180,
      renderCell: (cellValues) => {
        return (
          cellValues.row.image_url && (
            <div>
              <img
                src={`${image_url}${cellValues.row.image_url}`}
                height={60}
                width={60}
              />
            </div>
          )
        );
      },
    },
    // { field: "message", headerName: "Message ", width: 390 },
    // { field: "answer", headerName: "Answer", width: 320 },
    {
      field: "createdAt",
      headerName: "CreatedOn",
      width: 220,
      renderCell: (cellValues) => {
        return moment(cellValues.row.createdAt).format(
          "MMM-DD-YYYY, h:mm:ss a"
        );
      },
    },

    {
      field: "Action",
      width: 290,

      renderCell: (cellValues) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <Button
                variant="contained"
                color="success"
                onClick={(event) => {
                  HandleResend(event, cellValues);
                }}
              >
                Resend
              </Button>
            </div>
            <div style={{ marginLeft: 10 }}>
              <Button
                variant="contained"
                color="error"
                onClick={(event) => {
                  handleDelete(event, cellValues);
                }}
              >
                DELETE
              </Button>{" "}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>

      <Typography
        variant="h5"
        align="center"
        color="secondary"
        style={{ padding: 20 }}
      >
        Notification List !
      </Typography>
      <CardContent>
        <Container>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={newData}
              columns={columns}
              rowsPerPageOptions={[]}
            />
            <Pagination
              count={Math.ceil(totalRecords / 10)}
              onChange={(event, value) => setPage(value)}
            />
          </div>
        </Container>
      </CardContent>
    </Wrapper>
  );
};

export default NotificationList;
