import React, { useState, useEffect } from "react";
// import Modal from 'react-bootstrap/Modal'
import { Wrapper } from "../../components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { Card, CardContent, Container } from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import api from "../../utils/API";
import { useHistory, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AdminProfile = () => {
  const history = useHistory();
  const [adminData, setAdminData] = useState("");
  const [admin_id, setAdmin_id] = useState("");
  const [userName, setUserName] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("message");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(async () => {
    const res = await api.post("/updateProfile", { admin_data: true });
    console.log(res.data.data[0]);
    setAdminData(res.data.data[0]);
    setUserName(res.data.data[0].admin_id);
    setAdmin_id(res.data.data[0]._id);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);

  const handleSubmit = async () => {
    // alert("edit")
    const result = await api.post("/updateProfile", {
      admin_id: admin_id,
      user_name: userName,
      old_password: oldPass,
      new_password: newPass,
    });
    if (result.data.status == 1) {
      setType("success");
      setMessage(result.data.message);
      setOpen(true);
    } else {
      setType("error");
      setMessage(result.data.message);
      setOpen(true);
      setNewPass("");
      setOldPass("");
    }

    setTimeout(() => {
      if (result.data.status == 1) {
        setAdmin_id("");
        history.push({ pathname: "/dashboard" });
      }
    }, "2000");
  };

  return (
    <Wrapper>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Card style={{ width: "100%" }}>
          <CardContent>
            <Typography color="light-black" align="center">
              Update Profile
            </Typography>
            <br />
            <TextField
              style={{ width: "50%" }}
              type="text"
              value={userName}
              onChange={(e) => {
                console.log("change ", e.target.value);
                setUserName(e.target.value);
              }}
              variant="filled"
              label="Username"
              required
            />
            <br />
            <br />

            {/* <CKEditor
              editor={ClassicEditor}
              data={answer}
              onReady={(editor) => {
                // console.log("editor ready to use", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAnswer(data);
                console.log(data);
              }}
            /> */}
            <TextField
              style={{ width: "50%" }}
              type="password"
              value={oldPass}
              onChange={(e) => {
                setOldPass(e.target.value);
              }}
              variant="filled"
              label="Old Password"
              required
            />

            <br />
            <br />
            <TextField
              style={{ width: "50%" }}
              type="password"
              value={newPass}
              onChange={(e) => {
                setNewPass(e.target.value);
              }}
              variant="filled"
              label="New Password"
              required
            />

            <br />
            <div style={{ margin: 20 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Update
              </Button>
            </div>
          </CardContent>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default AdminProfile;
