import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
import {
  Box,
  InputLabel,
  Button,
  Typography,
  Container,
  TextField,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import Select from "@mui/material/Select";
import api from "../../utils/API";
import DateTimePicker from "react-datetime-picker";
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";
import { useParams } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddContestCompnent() {
  const history = useHistory();
  const useparam = useParams();
  const { id } = useparam;
  console.log(id);
  // console.log("1",props.data.data)
  // data=(props==null)?"":props.data.data
  // const data= props?props.data.data:""

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [start_time, setStartTime] = useState("");
  const [end_time, setEndTime] = useState("");
  const [ticket_price, setTicketPrice] = useState("");
  const [winning_price, setWinningPrice] = useState("");
  const [result_time, setResultTime] = useState("");
  const [contest_type, setContestType] = useState("");
  const [heading, setHeading] = useState("Add");
  const [button, setButton] = useState("Submit");
  const [is_choosen, setIs_choosen] = useState(false);
  // const [id, setId] =useState("");
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("message");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);
  useEffect(async () => {
    if (id === ":id") {
      setEdit(false);
    } else {
      setEdit(true);
      const res = await api.post("/getContest", {
        contest_id: id,
        is_admin: 1,
      });
      // console.log(res.data.data);
      setHeading("Edit");
      setButton("Update");
      setData(res.data.data);
      setTitle(res.data.data.title);
      setDescription(res.data.data.description);
      setStartTime(res.data.data.start_time);
      setEndTime(res.data.data.end_time);
      setWinningPrice(res.data.data.winning_price);
      setTicketPrice(res.data.data.ticket_price);
      setResultTime(res.data.data.result_time);
      // setContestType(res.data.data.contest_type);
      setSelectedFile(res.data.data.image_url);
    }
  }, []);

  const handleSubmit = async () => {
    console.log("edit time log ", typeof end_time);

    if (edit) {
      let currTime = new Date().toISOString();

      if (title == "") {
        return setType("error"), setMessage("enter title"), setOpen(true);
      } else if (description == "") {
        return setType("error"), setMessage("enter description"), setOpen(true);
      } else if (start_time == "") {
        return setType("error"), setMessage("enter start time"), setOpen(true);
        // } else if (start_time.toISOString() < currTime) {
        //   return (
        //     setType("error"),
        //     setMessage("start time can't be in the past"),
        //     setOpen(true)
        //   );
      } else if (end_time == "") {
        return setType("error"), setMessage("enter end time"), setOpen(true);
      }

      if (end_time && start_time) {
        var endDate3 = new Date(end_time);
        var endDate = endDate3.toISOString();
        var startDate3 = new Date(start_time);
        var startDate = startDate3.toISOString();

        if (endDate < startDate) {
          return (
            setType("error"),
            setMessage("end time must be greater than start time"),
            setOpen(true)
          );
        }
      }
      if (ticket_price == "") {
        return (
          setType("error"), setMessage("enter ticket price"), setOpen(true)
        );
      }
      if (winning_price == "") {
        return (
          setType("error"), setMessage("enter winning price"), setOpen(true)
        );
      }
      if (result_time == "") {
        return setType("error"), setMessage("enter result time"), setOpen(true);
      }
      if (end_time && result_time) {
        var endDate1 = new Date(end_time).toISOString();
        var resultDate = new Date(result_time).toISOString();

        if (endDate1 > resultDate) {
          return (
            setType("error"),
            setMessage("result time must be greater than end time"),
            setOpen(true)
          );
        }
      }
      // if (contest_type == "") {
      //   return (
      //     setType("error"), setMessage("enter contest type"), setOpen(true)
      //   );
      // }

      // console.log("yahahahahahah", title);
      // formD1.append("tititti", 25);
      let formD = new FormData();

      formD.append("title", title);
      if (is_choosen) {
        formD.append("image_url", selectedFile);
        console.log("hii here");
      }

      formD.append("description", description);
      formD.append("start_time", start_time);
      formD.append("end_time", end_time);
      formD.append("ticket_price", ticket_price);
      formD.append("winning_price", winning_price);
      formD.append("result_time", result_time);
      // formD.append("contest_type", contest_type);
      formD.append("contest_id", data._id);

      // return false;

      const result = await api.post("/updateContest", formD);
      console.log(result.data);
      if (result.data.status == 1) {
        setType("success");
        setMessage(result.data.message);
        setOpen(true);
      } else {
        setType("error");
        setMessage(result.data.message);
        setOpen(true);
      }
      setEdit(false);
      setTimeout(() => {
        history.push({ pathname: "/contest-Setting/list-of-all-contest" });
      }, 2000);
    } else {
      console.log("start_time ", start_time);
      let currTime = new Date().toISOString();
      let start_time1 = new Date(start_time);
      let end_time1 = new Date(end_time);
      let result_time1 = new Date(result_time);
      console.log("hiii else me entry", start_time1);
      console.log("hiii else me entry");
      console.log("hiii else me entry");

      if (title == "") {
        return (
          setType("error"), setMessage("please enter title"), setOpen(true)
        );
      } else if (description == "") {
        return (
          setType("error"),
          setMessage("please enter description"),
          setOpen(true)
        );
      } else if (start_time == "") {
        console.log("checking everything");
        return (
          setType("error"), setMessage("please enter start time"), setOpen(true)
        );
      } else if (start_time1.toISOString() < currTime) {
        console.log("first time me");
        return (
          setType("error"),
          setMessage("start time can't be in the past"),
          setOpen(true)
        );
      } else if (end_time == "") {
        return (
          setType("error"), setMessage("please enter end time"), setOpen(true)
        );
      } else if (end_time1.toISOString() < start_time1.toISOString()) {
        return (
          setType("error"),
          setMessage("end time must be greater than start time"),
          setOpen(true)
        );
      } else if (ticket_price == "") {
        return (
          setType("error"),
          setMessage("please enter ticket price"),
          setOpen(true)
        );
      } else if (winning_price == "") {
        return (
          setType("error"),
          setMessage("please enter winning price"),
          setOpen(true)
        );
      } else if (result_time == "") {
        return (
          setType("error"),
          setMessage("please enter result time"),
          setOpen(true)
        );
      } else if (end_time1.toISOString() > result_time1.toISOString()) {
        return (
          setType("error"),
          setMessage("result time must be greater than end time"),
          setOpen(true)
        );
        // } else if (contest_type == "") {
        //   return (
        //     setType("error"), setMessage("enter contest type"), setOpen(true)
        //   );
      } else if (selectedFile == null) {
        return (
          setType("error"), setMessage("select Poster Image"), setOpen(true)
        );
      }
      let st = start_time ? start_time1.toISOString() : "";
      setStartTime(st);
      let et = end_time ? end_time1.toISOString() : "";
      setEndTime(et);
      let rt = result_time ? result_time1.toISOString() : "";
      setResultTime(rt);
      console.log("hiii api se pehle");
      const formD = new FormData();
      formD.append("image_url", selectedFile);
      formD.append("title", title);
      formD.append("description", description);
      formD.append("start_time", start_time);
      formD.append("end_time", end_time);
      formD.append("ticket_price", ticket_price);
      formD.append("winning_price", winning_price);
      formD.append("result_time", result_time);
      // formD.append("contest_type", contest_type);

      console.log("adsdsd", formD);
      // return false;

      const result = await api.post("/addContest", formD);
      console.log(result.data);
      if (result.data.status == 1) {
        setType("success");
        setMessage(result.data.message);
        setOpen(true);
        setEdit(false);
        setTitle("");
        setDescription("");
        setStartTime("");
        setEndTime("");
        setTicketPrice("");
        setWinningPrice("");
        setResultTime("");
        // setContestType("");
        setSelectedFile(null);
        document.getElementById("1").value = "";
        document.getElementById("2").value = "";
        document.getElementById("3").value = "";
        document.getElementById("4").value = "";
        document.getElementById("5").value = "";
        document.getElementById("6").value = "";
        document.getElementById("7").value = "";
        // document.getElementById("8").value = "";
        document.getElementById("9").value = "";
      } else {
        setType("error");
        setMessage(result.data.message);
        setOpen(true);
        setTitle(title);
        setDescription(description);
        setStartTime(start_time);
        setEndTime(end_time);
        setTicketPrice(ticket_price);
        setWinningPrice(winning_price);
        setResultTime(result_time);
        // setContestType(contest_type);
        setSelectedFile(selectedFile);
      }
    }
  };

  return (
    <Wrapper>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>

      <Typography>
        <Card>
          <Typography variant="h4" align="center" color="secondary">
            {heading} Contest Here!
          </Typography>
          <CardContent>
            <Container>
              <Box
                style={{ textAlign: "center" }}
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  style={{ width: "70%" }}
                  id="1"
                  // value={edit ? data.title : title}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  label="Title"
                  variant="filled"
                />
                <br />
                <TextField
                  style={{ width: "70%" }}
                  id="2"
                  // value={edit ? data.description : description}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  label="Description"
                  variant="filled"
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDateTimePicker
                    // value={edit?data.start_time:start_time}
                    value={start_time}
                    onChange={(newValue) => {
                      newValue ? setStartTime(new Date(newValue)) : "";
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="3"
                        style={{ width: "70%" }}
                        {...params}
                        label="Start Time"
                        required
                        variant="filled"
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDateTimePicker
                    // value={edit?data.end_time:end_time}
                    value={end_time}
                    onChange={(newValue) => {
                      newValue ? setEndTime(new Date(newValue)) : "";
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="4"
                        style={{ width: "70%" }}
                        {...params}
                        label="End Time"
                        required
                        variant="filled"
                      />
                    )}
                  />
                </LocalizationProvider>
                <TextField
                  style={{ width: "70%" }}
                  type="number"
                  id="5"
                  // value={edit ? data.ticket_price : ticket_price}
                  value={ticket_price}
                  onChange={(e) => setTicketPrice(e.target.value)}
                  required
                  label="Ticket-Price "
                  variant="filled"
                />
                <TextField
                  style={{ width: "70%" }}
                  type="number"
                  id="6"
                  // value={edit ? data.winning_price : winning_price}
                  value={winning_price}
                  onChange={(e) => setWinningPrice(e.target.value)}
                  required
                  label="Winning-Price "
                  variant="filled"
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDateTimePicker
                    value={result_time}
                    onChange={(newValue) => {
                      newValue ? setResultTime(newValue) : "";
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="7"
                        style={{
                          width: "70%",
                          display: "flex",
                          marginLeft: "145px",
                        }}
                        {...params}
                        label="Result Time"
                        variant="filled"
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
                <TextField
                  id="9"
                  name="Image"
                  style={{ width: "70%" }}
                  type="file"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    setIs_choosen(true);
                  }}
                  variant="filled"
                  required
                />
                {/* <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    // style={{ width: "70px" }}
                  >
                    Select Contest Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    // style={{
                    //   width: "70%",
                    // }}
                    id="8"
                    // value={edit?data.contest_type:contest_type}
                    value={contest_type}
                    onChange={(e) => setContestType(e.target.value)}
                    required
                    // notched={true}
                    // shrink={true}
                    label="Select type of Contest "
                    variant="filled"
                  >
                    <MenuItem value="general">General</MenuItem>
                    <MenuItem value="featured">Featured</MenuItem>
                  </Select>
                </FormControl> */}
                <br />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                >
                  {button}
                </Button>
              </Box>
            </Container>
          </CardContent>
        </Card>
      </Typography>
    </Wrapper>
  );
}

export default AddContestCompnent;
