// Pages
import {
  AppBar,
  Autocomplete,
  Avatars,
  BackendError,
  Badges,
  Blank,
  ButtonNavigation,
  Buttons,
  Calendar,
  Cards,
  Charts,
  Chat,
  Chips,
  Detail,
  Dialogs,
  Dividers,
  Drawers,
  Editor,
  ExpansionPanels,
  Google,
  GridList,
  Home,
  Invoice,
  Leaflet,
  Lists,
  Lockscreen,
  Media,
  Menus,
  Messages,
  NotFound,
  Paper,
  PasswordReset,
  Pickers,
  PricingPage,
  Products,
  Progress,
  SelectionControls,
  Selects,
  Signin,
  Signup,
  Snackbars,
  Social,
  Steppers,
  Tables,
  Tabs,
  Taskboard,
  TextFields,
  TimelinePage,
  Tooltips,
  Widgets,
} from "./pages";

import WebsiteSetting from "./pages/WebsiteSetting/WebsiteSetting";
import AddContest from "./pages/ContestSetting/AddContest";
import PrizeDistribution from "./pages/ContestSetting/PrizeDistribution";
import ListofContest from "./pages/ContestSetting/ListofContest";
import ListofFeaturedContest from "./pages/ContestSetting/ListofFeaturedContest";
import SoldTicketList from "./pages/ContestSetting/SoldTicketList";

import BannerList from "./pages/AppSetting/BannerList";
import AppLogo from "./pages/AppSetting/AppLogo";
import ListFAQ from "./pages/AppSetting/ListFAQ";
import AddFAQ from "./pages/AppSetting/AddFAQ";
import Notification from "./pages/AppSetting/Notification";
import AddNotification from "./pages/NotificationSetting/AddNotification";
import NotificationList from "./pages/NotificationSetting/NotificationList";
import PushNotification from "./pages/AppSetting/PushNotification";
import PrivacyPolicy from "./pages/AppSetting/PrivacyPolicy";
import TermAndCondition from "./pages/AppSetting/TermAndCondition";
import SystemSetting from "./pages/SystemSetting/SystemSetting";
import RefferalCode from "./pages/AppSetting/RefferalCode";

import BlockedUser from "./pages/UserSettting/BlockedUser";
import ListAllUser from "./pages/UserSettting/ListAllUser";
import DeletedUser from "./pages/UserSettting/DeletedUser";
import WithdrawlRequest from "./pages/UserSettting/WithdrawlRequest";

import AppsIcon from "@material-ui/icons/Apps";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import EqualizerIcon from "@material-ui/icons/Equalizer";
// Icons
import ExploreIcon from "@material-ui/icons/Explore";
import FaceIcon from "@material-ui/icons/Face";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import Looks3Icon from "@material-ui/icons/Looks3";
import Looks4Icon from "@material-ui/icons/Looks4";
import NavigationIcon from "@material-ui/icons/Navigation";
import PagesIcon from "@material-ui/icons/Pages";
import PersonIcon from "@material-ui/icons/Person";
import PhotoIcon from "@material-ui/icons/Photo";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import homeIcon from "@material-ui/icons/Home";
import websiteIcon from "@material-ui/icons/Web";
import gameIcons from "@material-ui/icons/Games";
import personIcon from "@material-ui/icons/Person";
import Reports from "@material-ui/icons/Report";

import Contact from "./pages/AppSetting/Contact";
import About from "./pages/AppSetting/About";
import ReportSetting from "./pages/ReportSetting/ReportSetting";
import FundReportSetting from "./pages/ReportSetting/AddFundReport";
import WinnerList from "./pages/ReportSetting/WinnerList";

export default {
  items: [
    {
      path: "/dashboard",
      name: "Dashboard",
      type: "link",
      icon: homeIcon,
      component: Home,
    },

    {
      path: "/user-setting",
      name: "User Manager",
      type: "submenu",
      icon: personIcon,
      badge: {
        type: "error",
        value: "U",
      },
      children: [
        {
          path: "/all-user-list",
          name: "List All Users",
          component: ListAllUser,
        },
        {
          path: "/block-user",
          name: "Blocked Users",
          component: BlockedUser,
        },
        {
          path: "/deleted-user",
          name: "Deleted Users",
          component: DeletedUser,
        },
        {
          path: "/withdrawl-request",
          name: "Withdrawl Request",
          component: WithdrawlRequest,
        },
      ],
    },

    {
      path: "/contest-Setting",
      name: "Contest Manager",
      type: "submenu",
      icon: gameIcons,
      badge: {
        type: "error",
        value: "C",
      },
      children: [
        {
          path: "/list-of-all-contest",
          name: "List All Contest",
          component: ListofContest,
        },
        {
          path: "/add-contest/:id",
          name: "Add Contest",
          component: AddContest,
        },
        {
          path: "/sold-ticket-list",
          name: "Sold Ticket List",
          component: SoldTicketList,
        },
        {
          path: "/prize-distribution",
          name: "Prize Distribution ",
          component: PrizeDistribution,
        },
      ],
    },

    // {
    //   path: "/website-setting",
    //   name: "WebSite Setting",
    //   type: "link",
    //   icon: websiteIcon,
    //   component: WebsiteSetting,
    // },
    {
      path: "/website-setting",
      name: "WebSite Manager",
      type: "submenu",
      icon: websiteIcon,
      badge: {
        type: "primary",
        value: "W",
      },
      children: [
        {
          path: "/website-logo",
          name: "Website Logo",
          component: AppLogo,
        },
        // {
        //   path: "/privacy policy",
        //   name: "Privacy Policy",
        //   component: PrivacyPolicy,
        // },
        // {
        //   path: "/terms & conditions",
        //   name: "Terms And Conditions",
        //   component: TermAndCondition,
        // },
        // {
        //   path: "/about",
        //   name: "About ",
        //   component: About,
        // },
      ],
    },

    {
      path: "/app-setting",
      name: "App Manager",
      type: "submenu",
      icon: AppsIcon,
      badge: {
        type: "primary",
        value: "A",
      },
      children: [
        {
          path: "/banner-list",
          name: "Banner List",
          component: BannerList,
        },

        // {
        //   path: '/refferal-code',
        //   name: 'Refferal Code ',
        //   component: RefferalCode
        // },
        // {
        //   path: '/notification',
        //   name: 'Notification ',
        //   component: Notification
        // },
        // {
        //   path: '/push-notification',
        //   name: 'Push Notification ',
        //   component: PushNotification
        // },
        // {
        //   path: "/faq/:id",
        //   name: "Add FAQ ",
        //   component: AddFAQ,
        // },
        // {
        //   path: "/list-faq",
        //   name: "FAQ List",
        //   component: ListFAQ,
        // },
        // {
        //   path: "/contact",
        //   name: "Contact ",
        //   component: Contact,
        // },
      ],
    },

    {
      path: "/notification-setting",
      name: "Notification Manager",
      type: "submenu",
      icon: websiteIcon,

      children: [
        {
          path: "/add-notification",
          name: "Add Notification",
          component: AddNotification,
        },
        {
          path: "/notification-list",
          name: "Notification List",
          component: NotificationList,
        },
        // {
        //   path: "/terms & conditions",
        //   name: "Terms And Conditions",
        //   component: TermAndCondition,
        // },
        // {
        //   path: "/about",
        //   name: "About ",
        //   component: About,
        // },
      ],
    },

    {
      path: "/reports",
      name: "Reports",
      type: "submenu",
      icon: Reports,

      children: [
        {
          path: "/transaction-reports",
          name: "Transaction Reports",
          component: ReportSetting,
        },
        {
          path: "/fund-reports",
          name: "Add Fund Reports",
          component: FundReportSetting,
        },
        {
          path: "/winner-list",
          name: "Winner List",
          component: WinnerList,
        },
      ],
    },

    {
      path: "/system-setting",
      name: "System Setting",
      type: "link",
      icon: Reports,
      component: SystemSetting,
    },

    // {
    //   path: '/apps',
    //   name: 'Apps',
    //   type: 'submenu',
    //   icon: AppsIcon,
    //   badge: {
    //     type: 'primary',
    //     value: '5'
    //   },
    //   children: [
    //     {
    //       path: '/calendar',
    //       name: 'Calendar',
    //       component: Calendar
    //     },
    //     {
    //       path: '/media',
    //       name: 'Media',
    //       component: Media
    //     },
    //     {
    //       path: '/messages',
    //       name: 'Messages',
    //       component: Messages
    //     },
    //     {
    //       path: '/social',
    //       name: 'Social',
    //       component: Social
    //     },
    //     {
    //       path: '/chat',
    //       name: 'Chat',
    //       component: Chat
    //     }
    //   ]
    // },
    // {
    //   path: '/widgets',
    //   name: 'Widgets',
    //   type: 'link',
    //   icon: PhotoIcon,
    //   component: Widgets
    // },
    // {
    //   path: '/material',
    //   name: 'Material',
    //   type: 'submenu',
    //   icon: EqualizerIcon,
    //   badge: {
    //     type: 'error',
    //     value: '10'
    //   },
    //   children: [
    //     {
    //       path: '/appbar',
    //       name: 'App Bar',
    //       component: AppBar
    //     },
    //     {
    //       path: '/autocomplete',
    //       name: 'Auto Complete',
    //       component: Autocomplete
    //     },
    //     {
    //       path: '/avatars',
    //       name: 'Avatars',
    //       component: Avatars
    //     },
    //     {
    //       path: '/badges',
    //       name: 'Badges',
    //       component: Badges
    //     },
    //     {
    //       path: '/button-navigation',
    //       name: 'Button Navigation',
    //       component: ButtonNavigation
    //     },
    //     {
    //       path: '/buttons',
    //       name: 'Buttons',
    //       component: Buttons
    //     },
    //     {
    //       path: '/cards',
    //       name: 'Cards',
    //       component: Cards
    //     },
    //     {
    //       path: '/chips',
    //       name: 'Chips',
    //       component: Chips
    //     },
    //     {
    //       path: '/dialogs',
    //       name: 'Dialogs',
    //       component: Dialogs
    //     },
    //     {
    //       path: '/dividers',
    //       name: 'Dividers',
    //       component: Dividers
    //     },
    //     {
    //       path: '/drawers',
    //       name: 'Drawers',
    //       component: Drawers
    //     },
    //     {
    //       path: '/expansion-panels',
    //       name: 'Expansion Panels',
    //       component: ExpansionPanels
    //     },
    //     {
    //       path: '/grid-list',
    //       name: 'Grid List',
    //       component: GridList
    //     },
    //     {
    //       path: '/lists',
    //       name: 'Lists',
    //       component: Lists
    //     },
    //     {
    //       path: '/menus',
    //       name: 'Menus',
    //       component: Menus
    //     },
    //     {
    //       path: '/paper',
    //       name: 'Paper',
    //       component: Paper
    //     },
    //     {
    //       path: '/pickers',
    //       name: 'Pickers',
    //       component: Pickers
    //     },
    //     {
    //       path: '/progress',
    //       name: 'Progress',
    //       component: Progress
    //     },
    //     {
    //       path: '/selection-controls',
    //       name: 'Selection Controls',
    //       component: SelectionControls
    //     },
    //     {
    //       path: '/selects',
    //       name: 'Selects',
    //       component: Selects
    //     },
    //     {
    //       path: '/snackbars',
    //       name: 'Snackbars',
    //       component: Snackbars
    //     },
    //     {
    //       path: '/steppers',
    //       name: 'Steppers',
    //       component: Steppers
    //     },
    //     {
    //       path: '/tables',
    //       name: 'Tables',
    //       component: Tables
    //     },
    //     {
    //       path: '/tabs',
    //       name: 'Tabs',
    //       component: Tabs
    //     },
    //     {
    //       path: '/text-fields',
    //       name: 'Text Fields',
    //       component: TextFields
    //     },
    //     {
    //       path: '/tooltips',
    //       name: 'Tooltips',
    //       component: Tooltips
    //     }
    //   ]
    // },
    // {
    //   path: '/editor',
    //   name: 'Editor',
    //   type: 'link',
    //   icon: Looks3Icon,
    //   component: Editor
    // },
    // {
    //   path: '/ecommerce',
    //   name: 'Ecommerce',
    //   type: 'submenu',
    //   icon: Looks4Icon,
    //   badge: {
    //     type: 'secondary',
    //     value: 'N'
    //   },
    //   children: [
    //     {
    //       path: '/products',
    //       name: 'Products',
    //       component: Products
    //     },
    //     {
    //       path: '/detail',
    //       name: 'Detail',
    //       component: Detail
    //     }
    //   ]
    // },
    // {
    //   path: '/taskboard',
    //   name: 'Taskboard',
    //   type: 'link',
    //   icon: ViewColumnIcon,
    //   component: Taskboard
    // },
    // {
    //   path: '/charts',
    //   name: 'Charts',
    //   type: 'link',
    //   icon: ShowChartIcon,
    //   component: Charts
    // },
    // {
    //   path: '/maps',
    //   name: 'Maps',
    //   type: 'submenu',
    //   icon: NavigationIcon,
    //   children: [
    //     {
    //       path: '/google',
    //       name: 'Google',
    //       component: Google
    //     },
    //     {
    //       path: '/leaflet',
    //       name: 'Leaflet',
    //       component: Leaflet
    //     }
    //   ]
    // },
    // {
    //   path: '/pages',
    //   name: 'Pages',
    //   type: 'submenu',
    //   icon: PagesIcon,
    //   children: [
    //     {
    //       path: '/invoice',
    //       name: 'Invoice',
    //       component: Invoice
    //     },
    //     {
    //       path: '/timeline',
    //       name: 'Timeline',
    //       component: TimelinePage
    //     },
    //     {
    //       path: '/blank',
    //       name: 'Blank',
    //       component: Blank
    //     },
    //     {
    //       path: '/pricing',
    //       name: 'Pricing',
    //       component: PricingPage
    //     }
    //   ]
    // },
    // {
    //   name: 'Authentication',
    //   type: 'submenu',
    //   icon: PersonIcon,
    //   children: [
    //     {
    //       path: '/signin',
    //       name: 'Signin',
    //       component: Signin
    //     },
    //     {
    //       path: '/signup',
    //       name: 'Signup',
    //       component: Signup
    //     },
    //     {
    //       path: '/forgot',
    //       name: 'Forgot',
    //       component: PasswordReset
    //     },
    //     {
    //       path: '/lockscreen',
    //       name: 'Lockscreen',
    //       component: Lockscreen
    //     }
    //   ]
    // },
    // {
    //   name: 'Error',
    //   type: 'submenu',
    //   icon: FaceIcon,
    //   children: [
    //     {
    //       path: '/404',
    //       name: '404',
    //       component: NotFound
    //     },
    //     {
    //       path: '/500',
    //       name: 'Error',
    //       component: BackendError
    //     }
    //   ]
    // },
    // {
    //   path: 'https://iamnyasha.github.io/react-primer-docs/',
    //   name: 'Documentation',
    //   type: 'external',
    //   icon: LocalLibraryIcon
    // },
    // {
    //   path: 'https://primer.fusepx.com',
    //   name: 'Get Angular Version',
    //   type: 'external',
    //   icon: BookmarkIcon
    // }
  ],
};
