import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import api from "../../utils/API";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { image_url } from "../../utils/API";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// import loginlogo from "/images/logo.12778cf6.jpg";

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "97vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center",
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
  },
  fullWidth: {
    width: "100%",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Signin = () => {
  const history = useHistory();
  const [admin_id, setAdmin_id] = useState("");
  const [password, setPassword] = useState("");
  const [userErr, setUserErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [refresh, setRefresh] = useState("lplp");
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("message");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (localStorage.getItem("accesstoken")) {
      history.push("/dashboard");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setUserErr("");
      setPassErr("");
    }, 500);
  }, [refresh]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (admin_id === "" && password === "") {
      setUserErr("Enter Username");
      setPassErr("Enter Password");
      setRefresh("3");
    }
    if (admin_id === "") {
      setUserErr("Enter Username");
      setRefresh("1");
    }
    if (password === "") {
      setPassErr("Enter Password");
      setRefresh("2");
    } else {
      const result = await api.post("/loginAdmin", { admin_id, password });
      if (result.data.status === 1) {
        console.log(result.data.data.access_token, "token is nukk");
        console.log(result.data.data.admin_id, "token is admin_id");
        localStorage.setItem("accesstoken", result.data.data.access_token);

        console.log("admin data", result.data);
        history.push("/dashboard");
      } else {
        setType("error"),
          setMessage("user name or password invalid"),
          setOpen(true);
      }
      console.log(result.data);
    }
  };
  const classes = useStyles();
  return (
    <div className={classNames(classes.session, classes.background)}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <Card>
            <Stack spacing={2} sx={{ width: "100%" }}>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity={type}
                  sx={{ width: "100%" }}
                >
                  {message}
                </Alert>
              </Snackbar>
            </Stack>
            <CardContent>
              <form onSubmit={handleLogin}>
                <div
                  className={classNames(classes.logo, `text-xs-center pb-xs`)}
                >
                  <Typography>
                    <img
                      // src={`${process.env.PUBLIC_URL}/static/images/logo-dark.svg`}
                      // src={require("../../images/loginlogo.jpg")}
                      src={`${image_url}/image/logo.png`}
                      alt=""
                      className="block"
                      height="100"
                      width="100"
                      // style={{
                      //   display: "flex !important",
                      //   alignItems: "center !important",
                      // }}
                    />
                  </Typography>
                  <Typography variant="caption">
                    Login in with your app id to continue.
                  </Typography>
                </div>
                <TextField
                  id="username"
                  label="Username"
                  value={admin_id}
                  onChange={(e) => {
                    setAdmin_id(e.target.value);
                  }}
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                />
                <Typography variant="caption" color="error">
                  {userErr}
                </Typography>
                <TextField
                  id="password"
                  label="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  className={classes.textField}
                  type="password"
                  fullWidth
                  margin="normal"
                />
                <Typography variant="caption" color="error">
                  {passErr}
                </Typography>

                {/* <FormControlLabel
                  control={<Checkbox value="checkedA" />}
                  label="Stayed logged in"
                  className={classes.fullWidth}
                /> */}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Login
                </Button>
                <br />
                {/* <div className="pt-1 text-md-center">
                  <Link to="/forgot">
                    <Button>Forgot password?</Button>
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/signup">
                    <Button>Create new account.</Button>
                  </Link>
                </div> */}
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Signin;
