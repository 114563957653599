import {
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@mui/material/CircularProgress";

// import Snackbar from "@material-ui/core/Snackbar";
// import Alert from "@material-ui/core/Alert";
import { Wrapper } from "../../components";
import api from "../../utils/API";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import { getTreeViewUtilityClass } from "@mui/lab";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PrizeDistribution() {
  const [val, setVal] = useState({});
  const history = useHistory();

  const [totalAmount, setTotalAmount] = useState(0);
  const [loader, setLoader] = useState("");
  const [totalSoldTicket, setToalSoldTicket] = useState(0);
  const [duplicateTotal, setDuplicateTotal] = useState(20000);
  const [contestList, setContestList] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [refresh, setRefresh] = useState(1);
  const [rank, setRank] = useState(1);
  const [name, setName] = useState("");
  const [ticket_number, setTicket_number] = useState("");
  const [prize, setprize] = useState();
  const [prizePrecent, setprizePercent] = useState();
  const [open, setOpen] = useState(false);
  let [soldTicketList, setSoldTicketList] = useState([]);
  let [soldTicketListBackup, setSoldTicketListBackup] = useState([]);
  const [restTicketDifference, setRestTicketDifference] = useState(0);
  const [amountAfterFirstDistribution, setAmountAfterFirstDistribution] =
    useState(0);

  const [rank1, setRank1] = useState(2);
  const [name1, setName1] = useState("");
  const [ticket_number1, setTicket_number1] = useState("");
  const [prize1, setprize1] = useState();
  const [prizePrecent1, setprizePercent1] = useState();

  const [rank2, setRank2] = useState(3);
  const [name2, setName2] = useState("");
  const [ticket_number2, setTicket_number2] = useState("");
  const [prize2, setprize2] = useState();
  const [prizePrecent2, setprizePercent2] = useState();

  const [rank3, setRank3] = useState(4);
  const [name3, setName3] = useState("");
  const [ticket_number3, setTicket_number3] = useState("");
  const [prize3, setprize3] = useState();
  const [prizePrecent3, setprizePercent3] = useState();
  const [databaseData, setDatabaseDate] = useState([]);

  const [ticket_range_start, setTicketRangeStart] = useState(5);
  const [ticket_range_end, setTicketRangeEnd] = useState();
  const [restAmount, setRestAmount] = useState();
  const [restPrecent, setRestPrecent] = useState();
  const [isEdit, setisEdit] = useState(false);

  const [other_distribution_money, setOther_distribution_money] = useState("");
  const [top_for_winner, settop_for_winner] = useState("");
  const [all_ticket, setall_ticket] = useState("");
  const [disable, setDisable] = useState(false);
  const [fund, setFund] = useState(0);
  const [showrestCustsection, setShowrestCustsection] = useState(false);
  // const [realFund, setRealFund] = useState(0);
  const [rest_obj, setRest_obj] = useState({
    start_rank: "",
    end_rank: "",
    amount: "",
    is_new: true,
    add_more_disable: false,
  });
  const [selectedContest, setSelectedContest] = useState({});
  const [rest_array, setRest_array] = useState([
    {
      start_rank: 5,
      end_rank: "",
      amount: "",
      is_new: true,
      add_more_disable: false,
    },
  ]);
  const [forSendEnd, setForSendEnd] = useState();
  const [open1, setOpen1] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("message");

  const handleClose1 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen1(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);

  useEffect(async () => {
    const res = await api.post("/getContest", { is_admin: 1, is_all: true });
    console.log(res.data.data);
    setContestList(res.data.data);
  }, []);

  useEffect(() => {
    setprize();
    setprize1();
    setprize2();
    setprize3();
    setTicket_number("");
    setTicket_number1("");
    setTicket_number2("");
    setTicket_number3("");
    setRest_array([
      {
        start_rank: 5,
        end_rank: "",
        end_rank_num: "",
        amount: "",
        is_new: true,
      },
    ]);
    setDisable(false);
    setisEdit(false);
  }, [selectedItem]);

  useEffect(() => {
    console.log(ticket_number);
  }, [ticket_number]);

  useEffect(async () => {
    const res = await api.post("/getTicket", {
      contest_id: selectedItem,
      only_list: true,
    });
    setSoldTicketList(res.data.data);
    setSoldTicketListBackup(res.data.data);
    setToalSoldTicket(res.data.data ? res.data.data.length : 0);
  }, [selectedItem]);

  const handleshowAmount = async (selected) => {
    console.log("inside prize distribution");
    if (selected !== "1") {
      console.log("inside prize distribution");
      const res = await api.post("/getContest", {
        contest_id: selected,
        is_admin: 1,
      });
      // console.log(res.data.data);

      if (res.data && res.data.data) {
        // setToalSoldTicket(res.data.data.total_sold_ticket);
        setTotalAmount(res.data.data.dist_amount_with_fund);
        setSelectedContest(res.data.data);

        setall_ticket(res.data.data.all_ticket);
        settop_for_winner(res.data.data.top_for_winner);
        // console.log(
        //   "res.data.data.other_distribution_money",
        //   res.data.data.other_distribution_money
        // );
        setOther_distribution_money(res.data.data.other_distribution_money);

        if (res.data && res.data.data && res.data.data.total_sold_ticket > 4) {
          setShowrestCustsection(true);
        } else {
          setShowrestCustsection(false);
        }

        if (res.data.data && res.data.data.rest_customer_array) {
          var setRestAarray = JSON.parse(res.data.data.rest_customer_array);
          console.log("setRestAarray", setRestAarray);
          setRest_array(setRestAarray);
          setDatabaseDate(setRestAarray);
        }

        if (
          res.data.data &&
          res.data.data.distributed &&
          res.data.data.distributed.length > 0
        ) {
          var t1 = _.findIndex(res.data.data.distributed, { prize_rank: 1 });
          var t2 = _.findIndex(res.data.data.distributed, { prize_rank: 2 });
          var t3 = _.findIndex(res.data.data.distributed, { prize_rank: 3 });
          var t4 = _.findIndex(res.data.data.distributed, { prize_rank: 4 });

          console.log("ttttttt", t1, t2, t3, t4);
          if (t1 >= 0) {
            var tkt1 =
              res.data.data.distributed[t1]["ticket_number"].toString();
            setTicket_number(tkt1);
            setprize(res.data.data.distributed[t1]["claim_prize"]);
          }
          if (t2 >= 0) {
            var tkt2 =
              res.data.data.distributed[t2]["ticket_number"].toString();
            setTicket_number1(tkt2);
            setprize1(res.data.data.distributed[t2]["claim_prize"]);
          }
          if (t3 >= 0) {
            var tkt3 =
              res.data.data.distributed[t3]["ticket_number"].toString();
            setTicket_number2(tkt3);
            setprize2(res.data.data.distributed[t3]["claim_prize"]);
          }
          if (t4 >= 0) {
            var tkt3 =
              res.data.data.distributed[t4]["ticket_number"].toString();
            setTicket_number3(tkt3);
            setprize3(res.data.data.distributed[t4]["claim_prize"]);
          }

          if (t1 >= 0 && t2 >= 0 && t3 >= 0 && t4 >= 0) {
            setDisable(true);
          }

          setisEdit(true);
        } else {
        }

        console.log("***********", rest_array);
      } else {
        setType("error");
        setMessage("Something went wrong");
        setOpen1(true);
      }

      setRefresh(refresh + 1);
    } else {
      setToalSoldTicket(0);
      setTotalAmount(0);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setFund(0);
  };

  const handleClose = () => {
    setFund(0);
    setOpen(false);
  };

  const handleAdd = async () => {
    console.log("dheeraj", selectedContest);
    const res = await api.post("/addFund", {
      contest_id: selectedItem,
      add_fund: fund,
    });

    setOpen(false);
    if (res.data.status == 1) {
      let newCon = selectedContest;
      console.log("newCon", newCon);
      newCon.added_fund = newCon.added_fund
        ? newCon.added_fund
        : 0 + parseFloat(fund);
      newCon.dist_amount_with_fund = newCon.dist_amount_with_fund
        ? newCon.dist_amount_with_fund + parseFloat(fund)
        : 0 + parseFloat(fund);
      setSelectedContest(newCon);
      setTotalAmount(selectedContest.dist_amount_with_fund);

      setType("success");
      setMessage(res.data.message);
      setOpen1(true);
      // setDisable(false);
    } else {
      setType("error");
      setMessage(res.data.message);
      setOpen1(true);
    }

    // console.log("funds are ", realFund);
  };

  //handle Main Prize distribution
  const handleMainPrize = async () => {
    let p = parseInt(prize);
    let p1 = parseInt(prize1);
    let p2 = parseInt(prize2);
    let p3 = parseInt(prize3);
    let tp = p + p1 + p2 + p3;

    if (tp > totalAmount) {
      return (
        setType("error"),
        setMessage("Don't have enough amount...please add funds"),
        setOpen1(true)
      );
    }
    console.log(tp, "tp**********");
    // const rest = totalAmount-tp
    // console.log(rest,"rest");
    setLoader("show_uperloader");
    const res = await api.post("/distributePrize", {
      contest_id: selectedItem,
      top_4_prize: "1",
      first_prize_ticket_number: ticket_number,
      second_prize_ticket_number: ticket_number1,
      third_prize_ticket_number: ticket_number2,
      fourth_prize_ticket_number: ticket_number3,
      first_prize: p,
      second_prize: p1,
      third_prize: p2,
      fourth_prize: p3,
      // admin_added_fund: realFund,
    });
    console.log(res.data.data);
    setOther_distribution_money(res.data.data.other_distribution_money);
    settop_for_winner(res.data.data.top_for_winner);
    setall_ticket(res.data.data.all_ticket);
    setTotalAmount(totalAmount - tp);
    setAmountAfterFirstDistribution(totalAmount - tp);
    // if (realFund && realFund != 0) {
    //   let restAmnt = totalAmount + parseFloat(realFund) - tp;
    //   console.log(realFund, totalAmount, restAmnt);
    //   setRealFund(0);
    //   setTotalAmount(restAmnt);
    // } else {
    //   setTotalAmount(totalAmount - tp);
    // }
    // console.log(res.data.data);

    if (res.data.status == 1) {
      setLoader("");
      setType("success");
      setMessage(res.data.message);
      setOpen1(true);
      setDisable(true);
    } else {
      setType("error");
      setMessage(res.data.message);
      setOpen1(true);
    }

    setRefresh(refresh + 1);
  };

  const handlAddMore = async (index) => {
    try {
      // console.log("index******", index,rest_obj, rest_obj[index])
      let restArrCloned = JSON.parse(JSON.stringify(rest_array));
      let difference = calculateRestTicketDifference(rest_array);
      if (difference > totalSoldTicket - 4) {
        setType("error");
        setMessage("Not enough tickets available");
        setOpen1(true);
      } else {
        // setTotalAmount(totalAmount - calculateRestTicketAmount());
        restArrCloned[index]["add_more_disable"] = true;
        var objj = {
          ...rest_obj,
          start_rank: parseInt(rest_array[rest_array.length - 1].end_rank) + 1,
          is_new: true,
        };
        setRest_obj(objj);
        restArrCloned.push(objj);
        console.log("restArrCloned*****", restArrCloned);
        setRest_array(restArrCloned);
        setTicketRangeStart(rest_obj.end_rank);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const calculateRestTicketDifference = (arr) => {
    let difference = 0;
    arr.map((element) => {
      let startRank = element.start_rank ? parseInt(element.start_rank) : 5;
      let endRank = parseInt(element.end_rank);
      difference = difference + (endRank - startRank);
    });
    return difference + 1; //also including start rank
  };

  const calculateRestTicketAmount = () => {
    let amount = 0;
    rest_array.map((element) => (amount = amount + parseInt(element.amount)));
    return amount;
  };

  // useEffect(() => {
  //   setTotalAmount(totalAmount - calculateRestTicketAmount());
  // }, [rest_array]);

  const handlRestPrize = async () => {
    try {
      var restkt = await checkTicketlengthBeforClick();
      console.log("restkt", restkt);
      if (restkt == false) {
        return false;
      }
      // return false

      let amnt = 0;
      rest_array.map((e) => {
        if (e.is_new) {
          var fnRank =
            e.end_rank && e.end_rank > e.start_rank
              ? parseInt(e.end_rank) + 1 - parseInt(e.start_rank)
              : 1;

          console.log("fnRank", fnRank, e.amount);
          amnt += parseFloat(fnRank * e.amount);
        }
      });

      // console.log('rest_array', amnt, totalAmount)

      // console.log("ddddddddddd", {
      //   other_distribution_money: rest_obj.amount,
      //   top_for_winner,
      //   all_ticket,
      //   rest_prize: restAmount,
      //   // upperLimit: ticket_range_end,
      //   rest_price: 1,
      //   contest_id: selectedItem,
      //   data_array: rest_array,
      //   end: rest_obj.end_rank,
      // });

      // return false

      if (amnt > totalAmount) {
        return (
          setType("error"),
          setMessage("Don't have enough amount...please add funds"),
          setOpen1(true)
        );
        return false;
      }
      // console.log(restAmount,  ticket_range_start, ticket_range_end)
      setLoader("show_lowerloader");
      let restArrCloned = JSON.parse(JSON.stringify(rest_array));
      const res = await api.post("/distributePrize", {
        other_distribution_money: rest_obj.amount,
        top_for_winner,
        all_ticket,
        rest_prize: restAmount,
        // upperLimit: ticket_range_end,
        rest_price: 1,
        contest_id: selectedItem,
        data_array: rest_array,
        end: rest_obj.end_rank,
      });
      // rest_array.map((e) => {
      //   e.amount += e.amount;
      // });
      if (res.data.status == 1) {
        setLoader("");
        setType("success");
        console.log("totalAmount*****", totalAmount, amnt);
        setTotalAmount(totalAmount - amnt);
        setMessage(res.data.message);
        setOpen1(true);
        // let money = totalAmount;
        // rest_array.map((e) => {
        //   money = money - e.amount;
        // });
        // setTotalAmount(money);
        var nwArr = [];
        if (restArrCloned.length > 0) {
          restArrCloned.forEach(function (i, j) {
            i["is_new"] = false;
            nwArr.push(i);
            if (j == restArrCloned.length - 1) {
              console.log("nwArr***", nwArr);
              setRest_array(nwArr);
            }
          });
        }
      } else {
        setType("error");
        setMessage(res.data.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log("error000", error);
    }
  };

  const checkTicketlengthBeforClick = () => {
    return new Promise((resolve, reject) => {
      try {
        let restArr = JSON.parse(JSON.stringify(rest_array));
        var disTiktTotal = 0;
        var totalAmount = 0;
        var processed = false;

        console.log("restArr", restArr);

        _.forEach(restArr, function (i, j) {
          console.log("****", i.end_rank, i.start_rank);
          disTiktTotal =
            disTiktTotal + (parseInt(i.end_rank) - parseInt(i.start_rank));

          if (i.is_new) {
            processed = true;
          }

          totalAmount = totalAmount + parseInt(i.amount);

          if (j == restArr.length - 1) {
            console.log(
              "disTiktTotal",
              disTiktTotal,
              totalAmount,
              totalSoldTicket,
              totalSoldTicket - 4
            );
            if (disTiktTotal > totalSoldTicket - 4) {
              setType("error");
              setMessage(
                `total end rank number can't be more then total no. of sold and distributed prize ticket`
              );
              setOpen1(true);
              resolve(false);
            } else if (processed) {
              resolve(true);
            } else {
              resolve(false);
            }
          }
        });
      } catch (error) {
        console.log("error checkTicketlengthBeforClick", error);
        resolve(true);
      }
    });
  };

  const removeSeletedticketArry = (ticket1, ticket2, ticket3, ticket4) => {
    try {
      var selectdtktArray = [
        { ticket: ticket1 },
        { ticket: ticket2 },
        { ticket: ticket3 },
        { ticket: ticket4 },
      ];

      // console.log("selectdtktArray", selectdtktArray);

      var clonedTktList = JSON.parse(JSON.stringify(soldTicketListBackup));
      // var clonedTkOritList = JSON.parse(JSON.stringify(soldTicketList));
      _.forEach(selectdtktArray, function (i, j) {
        if (i.ticket) {
          var indx = _.findIndex(clonedTktList, { ticket_number: i.ticket });
          if (indx >= 0) {
            clonedTktList.splice(indx, 1);
          }
        }

        if (j == 3) {
          setSoldTicketList(clonedTktList);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const randDifferenceCheck = (arr) => {
    let difference = 0;
    arr.map((element) => {
      let startRank = element.start_rank ? parseInt(element.start_rank) : 5;
      let endRank = parseInt(element.end_rank);
      difference = difference + (endRank + 1 - startRank);
    });
    return difference; //also including start rank
  };

  const checkEndEankCalCulation = (e, index) => {
    try {
      // console.log("e.target.value", e.target.value, totalSoldTicket)
      let restArr = JSON.parse(JSON.stringify(rest_array));
      let restObj = restArr[index];
      if (e.target.value && e.target.value <= restObj.start_rank) {
        setType("error");
        setMessage(`End rank can't be less then or equal to start rank`);
        setOpen1(true);
        return false;
      }

      let newObj = { ...restObj, end_rank: e.target.value };
      restArr[index] = newObj;
      console.log(
        "e.target.value",
        e.target.value,
        totalSoldTicket,
        index,
        newObj,
        restArr
      );

      let difference = randDifferenceCheck(restArr);

      console.log("difference", difference, totalSoldTicket - 4);
      // return false;
      if (difference > totalSoldTicket - 4) {
        setType("error");
        setMessage(
          `End rank can't be more then total no. of sold and distributed prize ticket`
        );
        setOpen1(true);
        let newObjd = {
          ...restObj,
          end_rank: null,
          end_rank_num: e.target.value,
        };
        restArr[index] = newObjd;
        setRest_array(restArr);
      } else if (e.target.value) {
        console.log("1212122");
        let newObjdd = {
          ...restObj,
          end_rank: e.target.value,
          end_rank_num: e.target.value,
        };
        restArr[index] = newObjdd;
        setRest_array(restArr);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const calculateAddMoreBtn = (index) => {
    try {
      // console.log(
      //   "selectedItem",
      //   selectedContest,
      //   disable,
      //   rest_array[index].end_rank,
      //   rest_array[index].amount
      // );

      // && (selectedContest.total_claim_ticket >= selectedContest.total_sold_ticket)
      var lastIndex = rest_array.length - 1;
      return (
        <>
          {rest_array.length > 1 &&
          index == 0 &&
          rest_array[0].add_more_disable ? (
            <Button
              color="secondary"
              variant="contained"
              // onClick={handlAddMore}
              disabled={true}
            >
              Add More
            </Button>
          ) : rest_array[index].add_more_disable && lastIndex != index ? (
            <Button
              color="secondary"
              variant="contained"
              // onClick={handlAddMore}
              disabled={true}
            >
              Add More
            </Button>
          ) : (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                handlAddMore(index);
              }}
              disabled={
                selectedItem &&
                disable &&
                rest_array[index].end_rank &&
                rest_array[index].amount
                  ? false
                  : true
              }
            >
              Add More
            </Button>
          )}
        </>
      );
    } catch (error) {}
  };

  return (
    <div>
      <Wrapper>
        <Container
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginInlineStart: "10%",
            marginInlineEnd: "10%",
            marginTop: 30,
          }}
        >
          {/* <select
            id="demo-simple-select"
            disabled={disable}
            style={{
              width: "80%",
              height: "5%",
              fontSize: 20,
              padding: 5,
              merginTop: "5%",
            }}
            onChange={(e) => {
              let selected = e.target.value;
              setSelectedItem(selected);
              setFund(0);
              // setRealFund(0);
              handleshowAmount(selected);
            }}
          >
            <option value={"1"}>Select Any Contest</option>
            {contestList.map((d, i) => {
              return <option value={d._id}>{d.title}</option>;
            })}
          </select> */}
          <Autocomplete
            key={(option) => option._id}
            // value={""}
            // disabled={disable}
            options={contestList}
            getOptionLabel={(option) => option.title}
            style={{
              width: "80%",
              height: "2%",
              fontSize: 20,
              padding: 5,
              merginTop: "5%",
            }}
            onChange={(event, value) => {
              setSelectedItem(value ? value._id : "");
              setFund(0);
              if (value) {
                handleshowAmount(value._id);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Contest"
                variant="outlined"
                fullWidth
              />
            )}
          />

          {/* <span><Button variant="contained"color="primary" onClick={handleshowAmount}>Show Amount</Button> </span> */}

          <br />
          <br />
          <br />
        </Container>
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Card
            style={{ backgroundColor: "#5D3FD3", width: "25%", color: "white" }}
          >
            <Wrapper>
              <Typography> Total Distributable Amount</Typography>
              <Typography>{totalAmount.toFixed(2)}</Typography>
            </Wrapper>
          </Card>
          <Wrapper>
            <Button
              // style={{ backgroundColor: "#4169E1" }}
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleClickOpen}
              disabled={selectedItem ? false : true}
            >
              Add Funds
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Add Fund Amount</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="amount"
                  label="amount"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    setFund(e.target.value);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAdd}>Add</Button>
              </DialogActions>
            </Dialog>
          </Wrapper>

          <Card
            style={{ backgroundColor: "#088F8F", width: "25%", color: "white" }}
          >
            <Wrapper>
              <Typography> Total Sold Ticket</Typography>
              <Typography>{totalSoldTicket}</Typography>
            </Wrapper>
          </Card>

          {/* <Card style={{backgroundColor:'#f44336', width:'25%'}}>
      <Wrapper>
      <Typography>  if any</Typography>
      <Typography>any</Typography>
      </Wrapper>
      </Card> */}
        </Container>
      </Wrapper>

      <Wrapper>
        <Card>
          <Wrapper>
            <CardContent>
              {/* {duplicateTotal} */}
              <Typography align="center">
                {" "}
                Top 4 Prize Distributition
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="1"
                  value={rank}
                  onChange={(e) => setRank(e.target.value)}
                  required
                  label="Rank"
                  variant="outlined"
                  disabled
                />
                {/* <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="2"
                  type="number"
                  value={ticket_number}
                  onChange={(e) => setTicket_number(e.target.value)}
                  required
                  label="Ticket Number"
                  variant="filled"
                /> */}

                <Autocomplete
                  key={selectedItem}
                  value={ticket_number ? ticket_number : ""}
                  disabled={isEdit ? true : false}
                  // inputValue={ticket_number ? ticket_number : ""}
                  options={
                    soldTicketList
                      ? soldTicketList.map((ele) => {
                          return ele.ticket_number;
                        })
                      : (soldTicketList = [])
                  }
                  style={{ width: 300 }}
                  onChange={(event, value) => {
                    setTicket_number(value);

                    removeSeletedticketArry(
                      value,
                      ticket_number1,
                      ticket_number2,
                      ticket_number3
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // InputProps={{ ...params.InputProps, endAdornment: null }}
                      label="Ticket Number"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                {/* <TextField style={{width:"28%",margin:"1%"}} id= "3" value= {name} onChange ={(e)=>setName(e.target.value)} required label="Customer Name" variant="filled" /> */}
                <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="4"
                  disabled={isEdit ? true : false}
                  value={prize ? prize : ""}
                  type="number"
                  onChange={(e) => {
                    setprize(e.target.value);
                  }}
                  // onMouseLeave={() => {
                  //   setprizePercent((100 * prize) / totalAmount);
                  // }}
                  required
                  label="Amount"
                  variant="outlined"
                />
                {/* <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="4"
                  value={prizePrecent ? prizePrecent : ""}
                  onChange={(e) => {
                    setprizePercent(e.target.value);
                  }}
                  onMouseLeave={() => {
                    setprize((totalAmount * prizePrecent) / 100);
                  }}
                  required
                  label="Amount Percent"
                  variant="filled"
                /> */}
                <br />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="1"
                  type="number"
                  value={rank1}
                  onChange={(e) => setRank1(e.target.value)}
                  required
                  label="Rank"
                  variant="outlined"
                  disabled
                />
                {/* <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="2"
                  type="number"
                  value={ticket_number1}
                  onChange={(e) => setTicket_number1(e.target.value)}
                  required
                  label="Ticket Number"
                  variant="outlined"
                /> */}
                <Autocomplete
                  key={selectedItem}
                  value={ticket_number1 ? ticket_number1 : ""}
                  // inputValue={ticket_number1 ? ticket_number1 : ""}
                  disabled={isEdit ? true : false}
                  options={soldTicketList.map((ele) => {
                    return ele.ticket_number;
                  })}
                  // getOptionLabel={(option) => option.title}
                  style={{ width: 300 }}
                  onChange={(event, value) => {
                    setTicket_number1(value);
                    removeSeletedticketArry(
                      ticket_number,
                      value,
                      ticket_number2,
                      ticket_number3
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ticket Number"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                {/* <TextField style={{width:"28%",margin:"1%"}} id= "3" value= {name1} onChange ={(e)=>setName1(e.target.value)} required label="Customer Name" variant="filled" /> */}
                <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="4"
                  type="number"
                  disabled={isEdit ? true : false}
                  value={prize1 ? prize1 : ""}
                  onChange={(e) => setprize1(e.target.value)}
                  // onMouseLeave={() => {
                  //   setprizePercent1((100 * prize1) / totalAmount);
                  // }}
                  required
                  label="Amount"
                  variant="outlined"
                />
                {/* <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="4"
                  value={prizePrecent1 ? prizePrecent1 : ""}
                  onChange={(e) => {
                    setprizePercent1(e.target.value);
                  }}
                  onMouseLeave={() => {
                    setprize1((totalAmount * prizePrecent1) / 100);
                  }}
                  required
                  label="Amount Percent"
                  variant="filled"
                /> */}
                <br />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="1"
                  type="number"
                  value={rank2}
                  onChange={(e) => setRank2(e.target.value)}
                  required
                  label="Rank"
                  variant="outlined"
                  disabled
                />
                {/* <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="2"
                  type="number"
                  value={ticket_number2}
                  onChange={(e) => setTicket_number2(e.target.value)}
                  required
                  label="Ticket Number"
                  variant="outlined"
                /> */}
                <Autocomplete
                  key={selectedItem}
                  value={ticket_number2 ? ticket_number2 : ""}
                  // inputValue={ticket_number2 ? ticket_number2 : ""}
                  disabled={isEdit ? true : false}
                  options={soldTicketList.map((ele) => {
                    return ele.ticket_number;
                  })}
                  // getOptionLabel={(option) => option.title}
                  style={{ width: 300 }}
                  onChange={(event, value) => {
                    setTicket_number2(value);
                    removeSeletedticketArry(
                      ticket_number,
                      ticket_number1,
                      value,
                      ticket_number3
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ticket Number"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                {/* <TextField style={{width:"28%",margin:"1%"}} id= "3" value= {name2} onChange ={(e)=>setName2(e.target.value)} required label="Customer Name" variant="filled" /> */}
                <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="4"
                  type="number"
                  disabled={isEdit ? true : false}
                  value={prize2 ? prize2 : ""}
                  onChange={(e) => setprize2(e.target.value)}
                  // onMouseLeave={() => {
                  //   setprizePercent2((100 * prize2) / totalAmount);
                  // }}
                  required
                  label="Amount"
                  variant="outlined"
                />
                {/* <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="4"
                  value={prizePrecent2 ? prizePrecent2 : ""}
                  onChange={(e) => setprizePercent2(e.target.value)}
                  onMouseLeave={() => {
                    setprize2((totalAmount * prizePrecent2) / 100);
                  }}
                  required
                  label="Amount Percent"
                  variant="filled"
                /> */}
                <br />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="1"
                  type="number"
                  value={rank3}
                  onChange={(e) => setRank3(e.target.value)}
                  required
                  label="Rank"
                  variant="outlined"
                  disabled
                />
                {/* <TextField
                  style={{ width: "28%", margin: "1%" }}
                  type="number"
                  id="2"
                  value={ticket_number3}
                  onChange={(e) => setTicket_number3(e.target.value)}
                  required
                  label="Ticket Number"
                  variant="outlined"
                /> */}
                <Autocomplete
                  key={selectedItem}
                  value={ticket_number3 ? ticket_number3 : ""}
                  // inputValue={ticket_number3 ? ticket_number3 : ""}
                  disabled={isEdit ? true : false}
                  options={soldTicketList.map((ele) => {
                    return ele.ticket_number;
                  })}
                  // isOptionEqualToValue
                  // getOptionLabel={(option) => option.title}
                  style={{ width: 300 }}
                  // onChange={(e) => console.log(e.target.value, "asur")}
                  onChange={(event, value) => {
                    setTicket_number3(value);
                    removeSeletedticketArry(
                      ticket_number,
                      ticket_number1,
                      ticket_number2,
                      value
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ticket Number"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                {/* <TextField style={{width:"28%",margin:"1%"}} id= "3" value= {name3} onChange ={(e)=>setName3(e.target.value)} required label="Customer Name" variant="filled" /> */}
                <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="4"
                  value={prize3 ? prize3 : ""}
                  disabled={isEdit ? true : false}
                  onChange={(e) => setprize3(e.target.value)}
                  // onMouseLeave={() => {
                  //   setprizePercent3((100 * prize3) / totalAmount);
                  // }}
                  required
                  label="Amount"
                  variant="outlined"
                />
                {/* <TextField
                  style={{ width: "28%", margin: "1%" }}
                  id="4"
                  value={prizePrecent3 ? prizePrecent3 : ""}
                  onChange={(e) => setprizePercent3(e.target.value)}
                  onKeyUp={() => {
                    setprize3((totalAmount * prizePrecent3) / 100);
                  }}
                  required
                  label="Amount Percent"
                  variant="filled"
                /> */}
                <br />
              </div>
            </CardContent>
            <div style={{ textAlign: "center" }}>
              <Button
                disabled={
                  disable
                    ? disable
                    : selectedItem &&
                      ticket_number &&
                      ticket_number1 &&
                      ticket_number2 &&
                      ticket_number3 &&
                      prize &&
                      prize1 &&
                      prize2 &&
                      prize3
                    ? false
                    : true
                }
                color="secondary"
                variant="contained"
                onClick={handleMainPrize}
              >
                Distribute Prize
                {loader == "show_uperloader" && (
                  <CircularProgress size="1.5rem" />
                )}
              </Button>
            </div>
          </Wrapper>
        </Card>
      </Wrapper>
      <br />

      <Wrapper>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open1}
            autoHideDuration={3000}
            onClose={handleClose1}
          >
            <Alert
              onClose={handleClose1}
              severity={type}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </Stack>

        {showrestCustsection && (
          <Card>
            <CardContent>
              <Typography align="center">
                {" "}
                Prize Distributition for Rest Customer
              </Typography>
              {rest_array.map((element, index) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      style={{ width: "20%", margin: "1%" }}
                      disabled
                      id="1"
                      value={
                        rest_array[index - 1]
                          ? parseInt(rest_array[index - 1].end_rank) + 1
                          : 5
                      }
                      required
                      label="Start Rank from"
                      variant="filled"
                    />
                    {element.is_new ? (
                      <>
                        <TextField
                          style={{ width: "20%", margin: "1%" }}
                          id="2"
                          type="number"
                          onBlur={(e) => checkEndEankCalCulation(e, index)}
                          required
                          label="End Rank to"
                          variant="outlined"
                          helperText={
                            rest_array[index].end_rank_num &&
                            rest_array[index].end_rank_num >
                              rest_array[index].start_rank
                              ? parseInt(rest_array[index].end_rank_num) +
                                1 -
                                parseInt(rest_array[index].start_rank) +
                                " Ticket Included"
                              : ""
                          }
                        />
                        <TextField
                          style={{ width: "20%", margin: "1%" }}
                          id="3"
                          type="number"
                          onChange={(e) => {
                            try {
                              let restArr = JSON.parse(
                                JSON.stringify(rest_array)
                              );
                              let restObj = restArr[index];
                              let newObj = {
                                ...restObj,
                                amount: e.target.value,
                              };
                              restArr[index] = newObj;
                              setRest_array(restArr);
                            } catch (error) {
                              console.log("error", error);
                            }
                          }}
                          required
                          label="Amount"
                          variant="outlined"
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          style={{ width: "20%", margin: "1%" }}
                          id="2"
                          type="number"
                          value={element.end_rank ? element.end_rank : ""}
                          disabled={isEdit ? true : false}
                          // onChange={(e) => checkEndEankCalCulation(e, index)}
                          required
                          label="End Rank to"
                          variant="outlined"
                          helperText={
                            rest_array[index].end_rank &&
                            rest_array[index].end_rank >
                              rest_array[index].start_rank
                              ? parseInt(rest_array[index].end_rank) +
                                1 -
                                parseInt(rest_array[index].start_rank) +
                                " Ticket Included"
                              : ""
                          }
                        />
                        <TextField
                          style={{ width: "20%", margin: "1%" }}
                          id="3"
                          type="number"
                          value={element.amount}
                          disabled={isEdit ? true : false}
                          // onChange={(e) => {
                          //   try {

                          //     let restArr = JSON.parse(JSON.stringify(rest_array));
                          //     let restObj = restArr[index];
                          //     let newObj = { ...restObj, amount: e.target.value };
                          //     restArr[index] = newObj;
                          //     setRest_array(restArr);
                          //   } catch (error) {
                          //     console.log("error", error)
                          //   }
                          // }}
                          required
                          label="Amount"
                          variant="outlined"
                        />
                      </>
                    )}

                    {/* {element.is_new || index != 0 ? ( */}
                    {/* {
                      element.is_new ?
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            handlAddMore(index);
                          }}
                          disabled={
                            selectedItem &&
                              disable &&
                              rest_array[index].end_rank &&
                              rest_array[index].amount
                              ? false
                              : true
                          }
                        >
                          Add More--
                        </Button> :
                        calculateAddMoreBtn(index)
                    } */}

                    {calculateAddMoreBtn(index)}

                    {element.is_new && index != 0 ? (
                      <Button
                        color="secondary"
                        variant="contained"
                        style={{ marginLeft: "10px" }}
                        onClick={(e) => {
                          let restArr = JSON.parse(JSON.stringify(rest_array));
                          restArr.splice(index, 1);
                          restArr[index - 1]["add_more_disable"] = false;
                          setRest_array(restArr);
                        }}
                      >
                        Delete
                      </Button>
                    ) : null}

                    {/* <TextField
                style={{ width: "20%", margin: "1%" }}
                id="4"
                disabled
                value={restPrecent ? restPrecent : ""}
                // onChange={(e) => setRestPrecent(e.target.value)}
                // onMouseLeave={() => {
                //   setRestAmount((totalAmount * restPrecent) / 100);
                // }}
                required
                label="Precent"
                variant="filled"
              /> */}
                    {/* <TextField style={{width:"18%",margin:"1%"}} id= "4" value= {prizePrecent3} onChange ={(e)=>setprizePercent3(e.target.value)} required label="Amount Percent" variant="filled" />   */}
                  </div>
                </>
              ))}
              {
                // (checkTicketlengthBeforClick) &&
                <div style={{ textAlign: "center" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handlRestPrize}
                    disabled={
                      selectedItem &&
                      disable &&
                      rest_array[rest_array.length - 1].end_rank &&
                      rest_array[rest_array.length - 1].amount
                        ? false
                        : true
                    }
                  >
                    Distribute Prize
                    {loader == "show_lowerloader" && (
                      <CircularProgress size="1.5rem" />
                    )}
                  </Button>
                </div>
              }
            </CardContent>
          </Card>
        )}
      </Wrapper>
    </div>
  );
}
