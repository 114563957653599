import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardMedia,
  Typography,
  TextField,
  InputLabel,
} from "@material-ui/core";
import axios from "axios";
import api from "../../utils/API";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SystemSetting = () => {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("message");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [min_withdrawl, setMin_withdrawl] = useState("");
  const [admin_share, setAdmin_share] = useState("");
  const [system_id, setSystem_id] = useState("");
  const [rz_id, setRz_id] = useState("");
  const [rz_sec, setRz_sec] = useState("");
  const [app_url, setApp_url] = useState("");
  const [app_version, setApp_version] = useState("");

  useEffect(async () => {
    const result = await api.post("/getSystem");
    setMin_withdrawl(result.data.data.min_withdrawl_amount);
    setAdmin_share(result.data.data.admin_share);
    setSystem_id(result.data.data._id);
    setRz_id(result.data.data.razor_pay_key_id);
    setRz_sec(result.data.data.razor_pay_secret_key);
    setApp_url(result.data.data.app_url);
    setApp_version(result.data.data.app_version);
    console.log(result.data.data[0]);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);

  const handleSubmit = async () => {
    const res = await api.post("/updateSystem", {
      system_id: system_id,
      min_withdrawl_amount: min_withdrawl,
      admin_share: admin_share,
      razor_pay_key_id: rz_id,
      razor_pay_secret_key: rz_sec,
      app_url: app_url,
      app_version: app_version,
    });
    if (res.data.status == 1) {
      setType("success");
      setMessage(res.data.message);
      setOpen(true);
    } else {
      setType("error");
      setMessage(res.data.message);
      setOpen(true);
    }
  };
  return (
    <Wrapper>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      <Card>
        <CardMedia style={{ textAlign: "center", padding: "15px" }}>
          <Typography>
            {/* <CKEditor
              editor={ClassicEditor}
              data={privacy_policy}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log( 'Editor is ready to use!', editor );
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setPrivacy_policy(data);
                // console.log( { event, editor, data } );
              }}
              onBlur={(event, editor) => {
                // console.log( 'Blur.', editor );
              }}
              onFocus={(event, editor) => {
                // console.log( 'Focus.', editor );
              }}
            /> */}
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ marginTop: "16px" }}>
                <label>Minimum Withdrawl Amount :</label>
              </div>
              <div>
                <TextField
                  style={{ width: "83%" }}
                  id="1"
                  // value={edit ? data.title : title}
                  value={min_withdrawl}
                  onChange={(e) => setMin_withdrawl(e.target.value)}
                  required
                  // label="Minimum Withdrawl Amount"
                  variant="outlined"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </div>
            </div>
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ marginTop: "16px", marginLeft: "25px" }}>
                <label>Admin Share Percentage :</label>
              </div>
              <div>
                <TextField
                  style={{ width: "80%" }}
                  id="2"
                  // value={edit ? data.description : description}
                  value={admin_share}
                  onChange={(e) => setAdmin_share(e.target.value)}
                  required
                  // label="Admin Share Percentage"
                  variant="outlined"
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </div>
            </div>
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ marginTop: "16px", marginLeft: "85px" }}>
                <label>Razorpay Key ID :</label>
              </div>
              <div>
                <TextField
                  style={{ width: "85%" }}
                  id="3"
                  // value={edit ? data.title : title}
                  value={rz_id}
                  onChange={(e) => setRz_id(e.target.value)}
                  required
                  // label="Minimum Withdrawl Amount"
                  variant="outlined"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </div>
            </div>
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ marginTop: "16px", marginLeft: "50px" }}>
                <label>Razorpay secret Key :</label>
              </div>
              <div>
                <TextField
                  style={{ width: "80%" }}
                  id="4"
                  // value={edit ? data.description : description}
                  value={rz_sec}
                  onChange={(e) => setRz_sec(e.target.value)}
                  required
                  // label="Admin Share Percentage"
                  variant="outlined"
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </div>
            </div>

            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ marginTop: "16px", marginLeft: "125px" }}>
                <label>App URL :</label>
              </div>
              <div>
                <TextField
                  style={{ width: "80%" }}
                  id="4"
                  // value={edit ? data.description : description}
                  value={app_url}
                  onChange={(e) => setApp_url(e.target.value)}
                  required
                  // label="Admin Share Percentage"
                  variant="outlined"
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </div>
            </div>
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ marginTop: "16px", marginLeft: "125px" }}>
                <label>App Version :</label>
              </div>
              <div>
                <TextField
                  style={{ width: "80%" }}
                  id="5"
                  // value={edit ? data.description : description}
                  value={app_version}
                  onChange={(e) => setApp_version(e.target.value)}
                  required
                  // label="Admin Share Percentage"
                  variant="outlined"
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </div>
            </div>
          </Typography>
          <br />
          <div style={{ margin: 20 }}></div>
        </CardMedia>
      </Card>
    </Wrapper>
  );
};

export default SystemSetting;
