import React from 'react';
import { Wrapper } from '../../components';
import AddContestCompnent from "../../components/custom component/AddContestComponent"

import { useLocation } from 'react-router-dom';

const AddContest = () => {
// const location =useLocation();
// const data =location.state
//   console.log("add c",location.state)
return(
  <Wrapper >
    <AddContestCompnent />
    
  </Wrapper>
);
}
export default AddContest;
