import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { Container, Typography, Box } from "@material-ui/core";
import api from "../../utils/API";
import Pagination from "@material-ui/lab/Pagination";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "90%",
  backgroundColor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function WinnerList() {
  const [openModal, setOpenModal] = useState(false);
  const [winnerList, setWinnerList] = useState([]);
  const [totalRecordsMod, setToatalRecordsMod] = useState(10);
  const [pageMod, setPageMod] = useState(1);
  const [cell, setCell] = useState([]);

  const handleOpenModal = async (event, data) => {
    setOpenModal(true);
    console.log("data is tgis", data.row);
    let ss = data.row._id;

    const res = await api.post("/winnerList", {
      contest_id: data.row._id,
      page: pageMod,
      pagesize: 10,
    });
    console.log("data is here", res.data);
    setWinnerList(res.data.data);
    setToatalRecordsMod(res.data.total);
  };

  const change = async (page, data) => {
    const res = await api.post("/winnerList", {
      contest_id: data.row._id,
      page: page,
      pagesize: 10,
    });
    console.log("data is here", res.data);
    setWinnerList(res.data.data);
    setToatalRecordsMod(res.data.total);
  };
  const handleCloseModal = () => setOpenModal(false);

  const history = useHistory();

  const [select, setSelection] = useState([]);
  const [contestlList, setContestList] = useState([]);

  const [refresh, setRefresh] = useState(1);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(10);
  // const [disable, setDisable] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("message");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);

  useEffect(async () => {
    const result = await api.post("/winnerListContest", {
      page: page,
      pagesize: 10,
    });
    setContestList(result.data.data);
    setTotalRecords(result.data.total);
  }, [page, refresh]);

  let newContestlList = [];
  contestlList.map((data, index) => {
    console.log("print kist", data);
    newContestlList.push({
      sn: index + 1 + (page - 1) * 10,
      id: data._id,
      _id: data._id,
      contest_name: data.contest_name,
      start_time: data.start_time,
      end_time: data.end_time,
      result_time: data.result_time,
    });
    console.log("list", totalRecords);
  });

  let newWinnerList = [];
  console.log("length", winnerList);
  winnerList.map((data, index) => {
    // console.log("1 kist", data.tickets.user_name);
    newWinnerList.push({
      snm: index + 1 + (pageMod - 1) * 10,
      id: data._id,
      _id: data._id,
      user_name: data.user_info.fname,
      mobile: data.user_info.mobile,
      winning_amount: data.claim_prize,
      ticket_number: data.ticket_info.ticket_number,
    });
    console.log("list", newWinnerList);
    console.log("tota", totalRecordsMod);
  });

  const columnsModal = [
    // { field: "_id", headerName: "ID", width: 50 },
    { field: "snm", headerName: "SN#", width: 80 },
    { field: "user_name", headerName: "User Name", width: 180 },
    {
      field: "mobile",
      headerName: "Phone No.",
      width: 200,
    },
    {
      field: "ticket_number",
      headerName: "Ticket Number",
      width: 200,
    },
    {
      field: "winning_amount",
      headerName: "Winning Amount",
      width: 140,
    },
  ];

  const columns = [
    // { field: "_id", headerName: "ID", width: 50 },
    { field: "sn", headerName: "SN#", width: 50 },
    { field: "contest_name", headerName: "Contest Name", width: 180 },
    {
      field: "start_time",
      headerName: "Start Time",
      width: 200,
      renderCell: (cellValues) => {
        return moment(cellValues.row.start_time).format("MMM-DD-YYYY, h:mm a");
      },
    },
    {
      field: "end_time",
      headerName: "End Time",
      width: 200,
      renderCell: (cellValues) => {
        return moment(cellValues.row.end_time).format("MMM-DD-YYYY, h:mm a");
      },
    },
    {
      field: "result_time",
      headerName: "Result Time",
      width: 200,
      renderCell: (cellValues) => {
        return moment(cellValues.row.end_time).format("MMM-DD-YYYY, h:mm a");
      },
    },
    {
      field: "Winners_list",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <Wrapper>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    handleOpenModal(event, cellValues);
                    setCell(cellValues);
                  }}
                >
                  Winners List
                </Button>
                <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div style={style}>
                    {/* getRowId={(row) => row._id} */}
                    <Pagination
                      count={Math.ceil(totalRecordsMod / 10)}
                      onChange={(event, value) => {
                        console.log("0", value);
                        setPageMod(1);
                        change(value, cell);
                      }}
                    />
                    <DataGrid
                      rows={newWinnerList}
                      columns={columnsModal}
                      rowsPerPageOptions={[]}
                    />
                  </div>
                </Modal>
              </div>
            </div>
          </Wrapper>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>

      <Container
        style={{
          backgroundColor: "#673147",
          color: "white",
          padding: 5,
          borderRadius: 10,
          boxShadow: "5px 10px 5px #888888",
        }}
      >
        <Typography align="center" variant="h4">
          Winners List
        </Typography>
      </Container>
      <br />
      <br />
      <br />
      <div style={{ height: 400, width: "100%" }}>
        {/* getRowId={(row) => row._id} */}
        <DataGrid
          rows={newContestlList}
          columns={columns}
          rowsPerPageOptions={[]}
        />
        <Pagination
          count={Math.ceil(totalRecords / 10)}
          onChange={(event, value) => setPage(value)}
        />
      </div>
    </Wrapper>
  );
}
