import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { Container, Typography, Box } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";
import api from "../../utils/API";
import moment from "moment";
import { data } from "../../utils/mock/chart";
import Pagination from "@material-ui/lab/Pagination";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { image_url } from "../../utils/API";
import { padding } from "@mui/system";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ListofContest() {
  const [select, setSelection] = useState("");
  const [contestList, setContesList] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(10);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("message");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);

  useEffect(async () => {
    const res = await api.post("/getContest", {
      is_admin: 1,

      page: page,
      pagesize: 10,
    });
    console.log(res.data.data);
    setContesList(res.data.data);
    setTotalRecords(res.data.total);
  }, [page, refresh]);

  let newData = [];
  contestList.map((data, index) => {
    newData.push({ sn: index + 1 + (page - 1) * 10, id: data._id, ...data });
  });

  console.log("print", newData);
  let currTime = new Date();
  console.log("current", currTime);

  const handleDelete = async (event, data) => {
    console.log(data.row);
    let contest_id = data.row._id;
    const res = await api.post("/updateContest", {
      delete_status: true,
      contest_id,
    });
    if (res.data.status == 1) {
      setType("success");
      setMessage(res.data.message);
      setOpen(true);
    } else {
      setType("error");
      setMessage(res.data.message);
      setOpen(true);
    }

    setRefresh(refresh + 1);
    // history.push("/contest-Setting/list-of-all-contest");
  };
  const handleCancel = async (event, data) => {
    console.log(data.row);
    let contest_id = data.row._id;
    console.log("after api call");

    const res = await api.post("/cancleContest", {
      contest_id,
    });
    if (res.data.status == 1) {
      setType("success");
      setMessage(res.data.message);
      setOpen(true);
    } else {
      setType("error");
      setMessage(res.data.message);
      setOpen(true);
    }

    setRefresh(refresh + 1);
    // history.push("/contest-Setting/list-of-all-contest");
  };

  const handleEdit = (param, event) => {
    console.log(param);
    console.log("edit", event.row._id);
    let id = event.row._id;
    history.push({
      pathname: `/contest-Setting/add-contest/${id}`,
    });
  };

  const columns = [
    // { field: '_id', headerName: 'ID', width: 10 },
    { field: "sn", headerName: "SN#", width: 10 },
    { field: "title", headerName: "Contest Name", width: 130 },
    {
      field: "image_url",
      headerName: "Poster Image ",
      width: 120,
      renderCell: (cellValues) => {
        console.log("first", `${image_url}${cellValues.row.image_url}`);
        return (
          <div>
            <img
              src={`${image_url}${cellValues.row.image_url}`}
              // src="http://localhost:2800/public/Poster/1654174838790.jpg"
              height={60}
              width={60}
            />
          </div>
        );
      },
    },
    // { field: "contest_type", headerName: "Contest Type", width: 120 },
    {
      field: "start_time",
      headerName: "Start Time ",
      width: 190,
      renderCell: (cellValues) => {
        return moment(cellValues.row.start_time).format(
          "MMM-DD-YYYY, h:mm:ss a"
        );
      },
    },
    {
      field: "end_time",
      headerName: "End Time ",
      width: 190,
      renderCell: (cellValues) => {
        return moment(cellValues.row.end_time).format("MMM-DD-YYYY, h:mm:ss a");
      },
    },
    { field: "ticket_price", headerName: "Ticket Price", width: 90 },
    { field: "winning_price", headerName: "Winning Price", width: 110 },
    {
      field: "result_time",
      headerName: "Result Date",
      width: 190,
      renderCell: (cellValues) => {
        return moment(cellValues.row.result_time).format(
          "MMM-DD-YYYY, h:mm:ss a"
        );
      },
    },
    {
      field: "Action",
      width: 310,
      renderCell: (cellValues) => {
        let st_time = new Date(cellValues.row.start_time);
        let en_time = new Date(cellValues.row.end_time);
        return cellValues.row.is_canceled == false ? (
          <div
            style={{ display: "flex", flexDirection: "row", padding: "25px" }}
          >
            {currTime < en_time ? (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    setSelection(cellValues.row);
                    handleEdit(event, cellValues);
                  }}
                >
                  EDIT
                </Button>
              </div>
            ) : (
              <div>
                <Button variant="contained" color="primary" disabled={true}>
                  Closed
                </Button>
              </div>
            )}
            <div style={{ marginLeft: 10 }}>
              <Button
                variant="contained"
                color="error"
                onClick={(event) => {
                  handleDelete(event, cellValues);
                }}
              >
                DELETE
              </Button>{" "}
            </div>

            {currTime > st_time && en_time > currTime && (
              <div style={{ marginLeft: 10 }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#242526" }}
                  // color="#9e9e9e"
                  onClick={(event) => {
                    handleCancel(event, cellValues);
                  }}
                >
                  CANCEL
                </Button>{" "}
              </div>
            )}
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <Button variant="contained" color="primary" disabled={true}>
                Cancelled
              </Button>
            </div>
            <div style={{ marginLeft: 10 }}>
              <Button
                variant="contained"
                color="error"
                onClick={(event) => {
                  handleDelete(event, cellValues);
                }}
              >
                DELETE
              </Button>{" "}
            </div>
          </div>
        );
      },
    },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Wrapper>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>

      <Container
        style={{
          backgroundColor: "#673147",
          color: "white",
          padding: 5,
          borderRadius: 10,
          boxShadow: "5px 10px 5px #888888",
        }}
      >
        <Typography align="center" variant="h4">
          List of All Contest
        </Typography>
      </Container>
      <br />
      <br />
      <br />
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          // rows={contestList}
          rows={newData}
          columns={columns}
          rowsPerPageOptions={[]}
        />
        <Pagination
          count={Math.ceil(totalRecords / 10)}
          onChange={(event, value) => setPage(value)}
        />
      </div>
    </Wrapper>
  );
}
