import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@material-ui/lab/Pagination";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

import {
  Box,
  InputLabel,
  Button,
  Typography,
  Container,
  TextField,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import Select from "@mui/material/Select";
import api from "../../utils/API";
import { image_url } from "../../utils/API";
// import { Buffer } from 'buffer';
// import {uploadFile} from "../../helpers"
// window.Buffer = window.Buffer || Buffer;
const BannerList = () => {
  const history = useHistory();

  const [banner_name, setBanner_name] = useState("");
  const [banner_url, setBanner_url] = useState("");
  const [banner_id, setBanner_id] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [button, setButton] = useState("ADD");
  const [heading, setHeading] = useState("Add");

  const [refresh, setrefresh] = useState(1);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(10);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("message");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(async () => {
    const res = await api.post("/getBanner", {
      page: page,
      pagesize: 10,
      is_admin: true,
    });
    setData(res.data.data);
    setTotalRecords(res.data.total);
  }, [page, refresh]);

  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);

  let newdata = [];
  data.map((data, index) => {
    newdata.push({ sn: index + 1 + (page - 1) * 10, id: data._id, ...data });
  });
  const handleDelete = async (param, data) => {
    console.log("delete", data.row);
    const res = await api.post("/updateBanner", {
      delete_status: true,
      banner_id: data.row._id,
    });
    if (res.data.status == 1) {
      setType("success");
      setMessage(res.data.message);
      setOpen(true);
    } else {
      setType("error");
      setMessage(res.data.message);
      setOpen(true);
    }
    setrefresh(refresh + 1);
  };

  const handleEdit = async (param, data) => {
    window.scrollTo(0, 0);
    setButton("UPDATE");
    setHeading("Update");
    setEdit(true);
    // console.log(edit);
    console.log(data.row);
    // setBanner_name(data.row.banner_name);
    setBanner_url(data.row.banner_url);
    setBanner_id(data.row._id);
  };

  const handleSubmit = async () => {
    console.log("hello there");
    if (edit) {
      const formD = new FormData();
      if (!selectedFile) {
        return setType("error"), setMessage("select image"), setOpen(true);
      }
      formD.append("banner_url", selectedFile);
      formD.append("banner_name", banner_name);
      formD.append("banner_id", banner_id);
      console.log(selectedFile, banner_name);
      const res = await api.post("/updateBanner", formD);
      console.log(res.data.message);
      if (res.data.status == 1) {
        setType("success");
        setMessage(res.data.message);
        setOpen(true);
      } else {
        setType("error");
        setMessage(res.data.message);
        setOpen(true);
      }
      document.getElementById("2").value = "";
      setBanner_url("");
      setBanner_id("");
      setHeading("Add");
      setButton("ADD");
      setSelectedFile(null);

      // document.getElementById("1").value = "";
      setrefresh(refresh + 1);

      // var indx = 0;
      // var deepData = [...newdata];
      // deepData[indx][banner_name] = banner_name;
      // setData(deepData);
      setEdit(false);
    } else {
      console.log("i am hitted");
      if (!selectedFile) {
        return setType("error"), setMessage("select image"), setOpen(true);
      }
      // alert("Add");
      const formD = new FormData();
      formD.append("banner_url", selectedFile);
      // formD.append("banner_name", banner_name);
      console.log(formD);
      // console.log(selectedFile, banner_name);
      // alert("hello");
      const result = await api.post("/addBanner", formD);
      if (result.data.status == 1) {
        setType("success");
        setMessage(result.data.message);
        setOpen(true);
      } else {
        setType("error");
        setMessage(result.data.message);
        setOpen(true);
      }
      setrefresh(refresh + 1);
      document.getElementById("2").value = "";
    }
  };

  const columns = [
    { field: "sn", headerName: "SN#", width: 120 },
    // { field: "banner_name", headerName: 'Banner Name', width: 220 },
    {
      field: "banner_url",
      headerName: "Banner ",
      width: 320,
      renderCell: (cellValues) => {
        return (
          <div>
            <img
              src={`${image_url}${cellValues.row.banner_url}`}
              height={60}
              width={60}
            />
          </div>
        );
      },
    },
    {
      field: "Action",
      width: 290,
      renderCell: (cellValues) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <Button
                variant="contained"
                color="error"
                onClick={(event) => {
                  handleEdit(event, cellValues);
                }}
              >
                EDIT
              </Button>
            </div>
            <div style={{ marginLeft: 10 }}>
              <Button
                variant="contained"
                color="error"
                onClick={(event) => {
                  handleDelete(event, cellValues);
                }}
              >
                DELETE
              </Button>{" "}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>

      <Typography>
        <Card>
          <Typography variant="h4" align="center" color="secondary">
            {heading} Banner Here!
          </Typography>
          <CardContent>
            <Container id="cont">
              <Box
                style={{ textAlign: "center" }}
                component="form"
                sx={{
                  "& > :not(style)": { m: 1 },
                }}
                noValidate
                autoComplete="off"
              >
                {/* <form onSubmit={handleSubmit}> */}
                {banner_url && (
                  <div>
                    <img
                      src={`${image_url}${banner_url}`}
                      height={60}
                      width={60}
                    />
                  </div>
                )}
                {/* <TextField
                  style={{ width: "70%" }}
                  id="1"
                  value={banner_name}
                  onChange={(e) => setBanner_name(e.target.value)}
                  required
                  label="Banner Name"
                  variant="filled"
                /> */}
                <TextField
                  name="file_input"
                  style={{ width: "70%" }}
                  type="file"
                  id="2"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  variant="filled"
                  required
                  // {...handleSubmit("file_input")}
                  // error={errors.file_input ? true : false}
                />
                <br /> <br />{" "}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                >
                  {button}
                </Button>
                {/* </form> */}
              </Box>
            </Container>
          </CardContent>
        </Card>
      </Typography>
      <br />

      <Typography variant="h4" align="center" color="secondary">
        Banner List Here!
      </Typography>
      <CardContent>
        <Container>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={newdata}
              columns={columns}
              rowsPerPageOptions={[]}
            />
            <Pagination
              count={Math.ceil(totalRecords / 10)}
              onChange={(event, value) => setPage(value)}
            />
          </div>
        </Container>
      </CardContent>
    </Wrapper>
  );
};

export default BannerList;
