import { Bar, Bubble } from "react-chartjs-2";
import {
  NewsCard,
  PostCard,
  StatCard,
  WeatherCard,
  Wrapper,
} from "../../components";
import React, { useState, useEffect } from "react";
import { mockDashboard, mockFeed } from "../../utils/mock";
import { Link } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import EmailIcon from "@material-ui/icons/Email";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreIcon from "@material-ui/icons/More";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import MoneyIcon from "@material-ui/icons/Money";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
// import CurrencyRupeeIcon from "@mui/@material-ui/icons/CurrencyRupee";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import BlockIcon from "@material-ui/icons/Block";
import AttachMoney from "@material-ui/icons/AttachMoney";
import CancelPresentation from "@material-ui/icons/CancelPresentation";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import NotificationsActive from "@material-ui/icons/NotificationsActive";
import Paper from "@material-ui/core/Paper";
// import PhoneIcon from "@material-ui/icons/Phone";
// import SettingsIcon from "@material-ui/icons/Settings";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import red from "@material-ui/core/colors/red";
import api from "../../utils/API";
import { useHistory } from "react-router-dom";

let id = 0;
function createData(name, date, progress) {
  id += 1;
  return { id, name, date, progress };
}

const data = [
  createData("UI prototyping", "January 23", 67),
  createData("Design", "February 2", 87),
  createData("Development", "March 30", 54),
  createData("Testing and delivery", "April 12", 34),
  createData("Ongoing maintanance", "May 28", 56),
  createData("Extensive review", "December 3", 56),
  createData("Extensive testing", "December 25", 56),
];

const Home = () => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [active_contest, setActive_contest] = useState();
  const [total_earning, setTotal_earning] = useState();
  const [active_users, setActive_users] = useState();
  const [soldTicket, setSoldTicket] = useState();
  const [blocked_users, setBlocked_users] = useState();
  const [closed_contest, setClose_contest] = useState();
  const [pending_withdrawl, setPending_withdrawl] = useState();
  const [total_distribution, setTotal_distribution] = useState();

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);
  useEffect(async () => {
    const res = await api.post("/getDashboard");
    setActive_contest(res.data.data.active_contest);
    setTotal_earning(res.data.data.total_earning);
    setActive_users(res.data.data.active_users);
    setSoldTicket(res.data.data.total_sold_ticket);
    setBlocked_users(res.data.data.blocked_users);
    setClose_contest(res.data.data.closed_contest);
    setPending_withdrawl(res.data.data.pending_withdrawl);
    setTotal_distribution(res.data.data.total_distribution);
    console.log(res.data.data);
  }, []);

  const chartMenu = (
    <Menu
      id="chart-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose}>
        <ListItemIcon>{/* <SettingsIcon /> */}</ListItemIcon>
        <ListItemText primary="Settings" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <MoreIcon />
        </ListItemIcon>
        <ListItemText primary="View more" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <NotificationsOffIcon />
        </ListItemIcon>
        <ListItemText primary="Disable notifications" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Remove panel" />
      </MenuItem>
    </Menu>
  );

  return (
    <Wrapper>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          {/* <Link to="/"> */}
          <StatCard
            type="fill"
            title="Admin Total Earning"
            value={total_earning ? `₹ ${total_earning.toFixed(2)}` : 0}
            icon={<MoneyIcon fontSize="large" color="red" />}
            color="#3f51b5"
            // onClick={() => {}}
          />
          {/* </Link> */}
        </Grid>

        <Grid item xs={6}>
          <Link
            to="user-setting/all-user-list"
            style={{ textDecoration: "none" }}
          >
            <StatCard
              // style={{ textDecoration: "none !important" }}
              type="fill"
              title=" Active Users"
              value={active_users ? active_users : 0}
              icon={<AccessibilityIcon fontSize="large" color="red" />}
              color="#9c27b0"
            />
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link
            to="contest-Setting/list-of-all-contest"
            style={{ textDecoration: "none" }}
          >
            <StatCard
              type="fill"
              value={active_contest ? active_contest : 0}
              title="Running Contest"
              icon={<AvTimerIcon fontSize="large" color="primary" />}
              color="#f44336"
            />
          </Link>
        </Grid>

        <Grid item xs={6}>
          <Link
            to="contest-Setting/sold-ticket-list"
            style={{ textDecoration: "none" }}
          >
            <StatCard
              type="fill"
              title="Total Sold Ticket"
              value={soldTicket ? soldTicket : 0}
              icon={<CardMembershipIcon fontSize="large" />}
              color="#0c401c"
            />
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link
            to="user-setting/withdrawl-request"
            style={{ textDecoration: "none" }}
          >
            <StatCard
              // style={{ textDecoration: "none !important" }}
              type="fill"
              title=" Pending Withdrawl"
              value={pending_withdrawl ? pending_withdrawl : 0}
              icon={<NotificationsActive fontSize="large" color="red" />}
              color="#00669B"
            />
          </Link>
        </Grid>

        <Grid item xs={6}>
          <Link
            to="contest-Setting/list-of-all-contest"
            style={{ textDecoration: "none" }}
          >
            <StatCard
              // style={{ textDecoration: "none !important" }}
              type="fill"
              title="Close Contest"
              value={closed_contest ? closed_contest : 0}
              icon={<CancelPresentation fontSize="large" color="red" />}
              color="#008080"
            />
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link
            to="user-setting/all-user-list"
            style={{ textDecoration: "none" }}
          >
            <StatCard
              // style={{ textDecoration: "none !important" }}
              type="fill"
              title="Total Distribution"
              value={
                total_distribution ? `₹ ${total_distribution.toFixed(2)}` : 0
              }
              icon={<AttachMoney fontSize="large" color="red" />}
              color="#977FD7"
            />
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link to="user-setting/block-user" style={{ textDecoration: "none" }}>
            <StatCard
              // style={{ textDecoration: "none !important" }}
              type="fill"
              title="Blocked Users"
              value={blocked_users ? blocked_users : 0}
              icon={<BlockIcon fontSize="large" color="red" />}
              color="#E30B5C"
            />
          </Link>
        </Grid>

        {chartMenu}
        {/* {mockDashboard.map((chart, index) => (
          <Grid item xs={12} sm={12} md={4} key={index}>
            <Card>
              <CardHeader
                subheader={chart.title}
                action={
                  <IconButton id={`${index}-menu-button`} onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                }
              />
              <CardContent>
                {chart.type === "bar" && (
                  <Bar
                    data={chart.data}
                    height={chart.height}
                    options={chart.options}
                  />
                )}
                {chart.type === "bubble" && (
                  <Bubble
                    data={chart.data}
                    height={chart.height}
                    options={chart.options}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        ))} */}
        {/* <Grid item xs={12} sm={12} md={8}>
          <Paper className="table-responsive">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Project</TableCell>
                  <TableCell>Due Date</TableCell>
                  <TableCell>Current Progress</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((n) => (
                  <TableRow key={n.id}>
                    <TableCell component="th" scope="row">
                      {n.name}
                    </TableCell>
                    <TableCell>{n.date}</TableCell>
                    <TableCell>
                      {
                        <LinearProgress
                          variant="determinate"
                          value={n.progress}
                        />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid> */}
        {/* <Grid item xs={12} sm={12} md={4}>
          <NewsCard subtitle="Last updated 24 mins ago" feed={mockFeed} />
        </Grid> */}
        {/* <Grid item sm={12} md={6}>
          <PostCard
            title="Shrimp and Chorizo Paella"
            subtitle="Yesterday"
            image={`${process.env.PUBLIC_URL}/static/images/unsplash/2.jpg`}
            imageHeight={200}
            text="Phileas Fogg and Aouda went on board, where they found Fix already installed. Below deck was a square cabin, of which the walls bulged out in the form of cots, above a circular divan; in the centre was a table provided with a swinging lamp."
            avatar={
              <Avatar aria-label="Post" style={{ backgroundColor: red[500] }}>
                R
              </Avatar>
            }
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <WeatherCard city="london" country="uk" days={7} />
        </Grid> */}
      </Grid>
      {/* <h2>Surendra</h2> */}
    </Wrapper>
  );
};

export default Home;
