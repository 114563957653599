import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { Container, Typography } from "@material-ui/core";
import api from "../../utils/API";
import Pagination from "@material-ui/lab/Pagination";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ListAllUser() {
  const history = useHistory();

  const [select, setSelection] = useState([]);
  const [withdrawlList, setWithdrawlList] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(10);
  // const [disable, setDisable] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("message");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);

  useEffect(async () => {
    const result = await api.post("/withdrawl", {
      is_admin: true,
      page: page,
      pagesize: 10,
    });
    setWithdrawlList(result.data.data);
    setTotalRecords(result.data.total);
  }, [page, refresh]);

  let newWithdrawlList = [];
  withdrawlList.map((data, index) => {
    console.log("print kist", data);
    newWithdrawlList.push({
      sn: index + 1 + (page - 1) * 10,
      id: data._id,
      _id: data._id,
      user_id: data.user_info._id,
      fname: data.user_info.fname,
      mobile: data.user_info.mobile,
      transaction_money: data.transaction_money,
      status: data.status,
      city: data.user_info.city,
      country: data.user_info.country,
      withdrawl_method: data.withdrawl_method,
    });
    console.log(newWithdrawlList);
  });

  const handleApprove = async (event, data) => {
    console.log("data is tgis", data.row);
    let ss = data.row._id;
    const res = await api.post("/withdrawl", {
      status: "approved",
      user_id: data.row.user_id,
      trans_id: data.row._id,
    });
    if (res.data.status == 1) {
      setType("success");
      setMessage(res.data.message);
      setOpen(true);
    } else {
      setType("error");
      setMessage(res.data.message);
      setOpen(true);
    }

    setRefresh(refresh + 1);
  };

  const handleDecline = async (event, data) => {
    let ss = data.row._id;
    const res = await api.post("/withdrawl", {
      status: "declined",
      trans_id: data.row._id,
      user_id: data.row.user_id,
    });
    if (res.data.status == 1) {
      setType("success");
      setMessage(res.data.message);
      setOpen(true);
    } else {
      setType("error");
      setMessage(res.data.message);
      setOpen(true);
    }
    setRefresh(refresh + 1);
  };

  const columns = [
    // { field: "_id", headerName: "ID", width: 50 },
    { field: "sn", headerName: "SN#", width: 50 },
    { field: "fname", headerName: "Name", width: 150 },
    { field: "mobile", headerName: "mobile", width: 130 },
    { field: "transaction_money", headerName: "Requested Money", width: 130 },
    { field: "withdrawl_method", headerName: "Withdrawl Method", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      // renderCell: (cellValues) => {
      //   console.log("cellvalue is this", cellValues);
      //   return cellValues ? "Pending" : "Declined";
      // },
    },
    // { field: "sex", headerName: "Gender", width: 100 },
    { field: "city", headerName: "City ", width: 120 },
    { field: "country", headerName: "Country ", width: 100 },
    {
      field: "Approve",
      width: 130,
      renderCell: (cellValues) => {
        return (
          cellValues.row.status == "pending" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  color="success"
                  onClick={(event) => {
                    handleApprove(event, cellValues);
                  }}
                  disabled={cellValues.row.status == "pending" ? false : true}
                >
                  Approve
                </Button>
              </div>
            </div>
          )
        );
      },
    },
    {
      field: "Decline",
      width: 130,
      renderCell: (cellValues) => {
        return (
          cellValues.row.status == "pending" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  color="error"
                  onClick={(event) => {
                    handleDecline(event, cellValues);
                  }}
                  disabled={cellValues.row.status == "pending" ? false : true}
                >
                  Decline
                </Button>
              </div>
            </div>
          )
        );
      },
    },
  ];

  return (
    <Wrapper>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      <Container
        style={{
          backgroundColor: "#673147",
          color: "white",
          padding: 5,
          borderRadius: 10,
          boxShadow: "5px 10px 5px #888888",
        }}
      >
        <Typography align="center" variant="h4">
          Withdrawl Request
        </Typography>
      </Container>
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (window) {
              window.location.reload();
            }
          }}
        >
          Refresh
        </Button>
      </div>
      <br />
      <div style={{ height: 400, width: "100%" }}>
        {/* getRowId={(row) => row._id} */}
        <DataGrid
          rows={newWithdrawlList}
          columns={columns}
          rowsPerPageOptions={[]}
        />
        <Pagination
          count={Math.ceil(totalRecords / 10)}
          onChange={(event, value) => setPage(value)}
        />
      </div>
    </Wrapper>
  );
}
