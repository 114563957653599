import { Card, CardMedia, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components";
import Button from "@mui/material/Button";
import api from "../../utils/API";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";

export default function AddFundReport() {
  const history = useHistory();

  const [selection, setSelection] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(10);

  useEffect(async () => {
    const res = await api.post("/getFundReport", { page: page, pagesize: 10 });
    console.log(res.data.data);
    setData(res.data.data);
    setTotalRecords(res.data.total);
  }, [page]);

  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);

  let newData = [];
  data.map((d, i) => {
    newData.push({
      ...d,
      sn: i + 1 + (page - 1) * 10,
      id: d._id,
      contest_name: d.contest_info.title,
      fund_amount: d.fund_amount,
    });
  });
  // console.log(newData);
  const handleBlock = (event, data) => {
    // console.log(event);
    // console.log("delete",data )
    // alert("delete")
  };

  const columns = [
    { field: "sn", headerName: "SN#", width: 50 },
    { field: "contest_name", headerName: "Contest Name", width: 250 },
    { field: "fund_amount", headerName: "Added Amount", width: 250 },

    {
      field: "createdAt",
      headerName: "created On",
      width: 200,
      renderCell: (cellValues) => {
        return moment(cellValues.row.createdAt).format(
          "MMM-DD-YYYY, h:mm:ss a"
        );
      },
    },
  ];

  return (
    <div>
      <Wrapper>
        <Typography variant="h5" color="textSecondary" align="center">
          Fund Reports{" "}
        </Typography>
        <CardMedia>
          <br />
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={newData}
              columns={columns}
              rowsPerPageOptions={[]}
            />
            <Pagination
              count={Math.ceil(totalRecords / 10)}
              onChange={(event, value) => setPage(value)}
            />
          </div>
        </CardMedia>
      </Wrapper>
    </div>
  );
}
