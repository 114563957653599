import React, { useState, useEffect } from "react";
import { Container, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";

import { Wrapper } from "../../components";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Button } from "@mui/material";
import api from "../../utils/API";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";

import { useHistory } from "react-router-dom";

export default function SoldTicketList() {
  const history = useHistory();

  const [selectedItem, setSelectedItem] = useState("");
  const [contestList, setContestList] = useState([]);
  const [soldTickit, setSoldTicket] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(10);

  useEffect(async () => {
    const res = await api.post("/getContest", { is_admin: 1, is_all: true });
    console.log(res.data.data);
    setContestList(res.data.data);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    console.log("s", soldTickit);
  }, [selectedItem, refresh]);

  const handleShowTicket = async (ss) => {
    //  console.log("first", selectedItem)

    const res = await api.post("/getTicket", {
      contest_id: ss,
      page: page,
      pagesize: 10,
    });
    console.log(res.data.data);
    setSoldTicket(res.data.data);
    setData(res.data.data);
    setTotalRecords(res.data.total);
    setRefresh(refresh + 0.1);
  };
  useEffect(() => {
    handleShowTicket(selectedItem);
  }, [page]);

  let newData = [];
  data
    ? data.map((d, i) => {
        newData.push({
          sn: i + 1 + (page - 1) * 10,
          _id: d._id,
          id: d._id,
          ticket_number: d.ticket_number,
          name: d.user_info.fname,
          mobile: d.user_info.mobile,
          createdAt: d.createdAt,
        });
      })
    : [];

  const columns = [
    { field: "sn", headerName: "SN#", width: 80 },
    { field: "ticket_number", headerName: "Ticket Number", width: 160 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "mobile", headerName: "Phone No.", width: 200 },
    {
      field: "createdAt",
      headerName: "Buy Time",
      width: 200,
      renderCell: (cellValues) => {
        return moment(cellValues.row.createdAt).format(
          "MMM-DD-YYYY, h:mm:ss a"
        );
      },
    },
  ];

  return (
    <div>
      <Wrapper>
        <Container
          style={{
            backgroundColor: "#673147",
            color: "white",
            padding: 5,
            borderRadius: 10,
            boxShadow: "5px 10px 5px #888888",
          }}
        >
          <Typography align="center" variant="h4">
            Sold Ticket Detail
          </Typography>
        </Container>
        <br />
        <br />
        <br />

        <Container color="primary">
          {/* <Typography align="center" variant="h5">Select Contest</Typography> */}
          {/* <Select
            style={{ width: "70%", marginInline: "10%", color: "black" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedItem}
            label="Select Contest"
            onChange={(e) => {
              console.log(e)
            }}
          >
            {contestList.map((data, index) => {
              return (
                <div key={index}>
                  <MenuItem value={10}>
                    {data.title}
                  </MenuItem>
                </div>
              );
            })}
          </Select> */}
          <div style={{ textAlign: "center" }}>
            <select
              id="demo-simple-select"
              style={{ width: "70%", height: "5%", fontSize: 20, padding: 5 }}
              onChange={(e) => {
                let ss = e.target.value;
                setSelectedItem(ss);
                handleShowTicket(ss);
              }}
            >
              <option>Select any Contest</option>
              {contestList.map((d, i) => {
                return <option value={d._id}>{d.title}</option>;
              })}
            </select>
            {/* <span style={{marginLeft:'10%',marginTop:-20}}><Button variant="contained" onClick={handleShowTicket}>Show Ticket</Button> </span> */}
          </div>
          {/* {selectedItem} */}
        </Container>
        <br />

        {/* <Wrapper>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  margin: "1%",
                }}
              >
                {soldTickit.length > 0 ? (
                  <>
                    {soldTickit.map((data, index) => {
                      return (
                        <div style={{ margin: "1%" }}>
                          <Button variant="outlined">
                            {data.ticket_number}{" "}
                          </Button>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <Typography>No any Ticket Sold</Typography>
                )}
              </Container>
            </CardContent>
          </Card>
        </Wrapper> */}
        <Wrapper>
          <br />
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={newData}
              columns={columns}
              rowsPerPageOptions={[]}
            />
            <Pagination
              count={Math.ceil(totalRecords / 10)}
              onChange={(event, value) => setPage(value)}
            />
          </div>
        </Wrapper>

        <br />
      </Wrapper>
    </div>
  );
}
