import React, { useState, useEffect } from "react";
// import Modal from 'react-bootstrap/Modal'
import { Wrapper } from "../../components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { Card, CardContent, Container } from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import api from "../../utils/API";
import { useHistory, useParams, Link, router } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// import { Router } from "express";

const AddNotification = () => {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [messageRes, setMessageRes] = useState("message");
  const [selectItem, setSelectedItem] = useState("");

  const targetArray = [
    { target_screen: "contest_screen", name: "Contest Screen" },
    { target_screen: "result_screen", name: "Result Screen" },
    { target_screen: "ticket_screen", name: "Ticket Screen" },
    { target_screen: "recharge_wallet_screen", name: "Recharge Wallet Screen" },
    { target_screen: "home_screen", name: "Home Screen" },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleSubmit = async () => {
    if (!title || !message) {
      return (
        setType("error"), setMessageRes("fill required fields"), setOpen(true)
      );
    }

    // alert("Add");
    const formD = new FormData();
    formD.append("image_url", selectedFile);
    formD.append("title", title);
    formD.append("message", message);
    formD.append("target_screen", selectItem);
    console.log("i am here");
    const result = await api.post("/addNotification", formD);
    if (result.data.status == 1) {
      setType("success");
      setMessageRes(result.data.message);
      setOpen(true);
    } else {
      setType("error");
      setMessageRes(result.data.message);
      setOpen(true);
    }
    setTitle("");
    setMessage("");
    setSelectedFile(null);
    setTimeout(() => {
      if (result.data.status == 1) {
        history.push({ pathname: "/notification-setting/notification-list" });
      } else {
        // history.push({ pathname: "/notification-setting/add-notification" });
        window.location.reload(false);
      }
    }, 800);
  };

  return (
    <Wrapper>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {messageRes}
          </Alert>
        </Snackbar>
      </Stack>

      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Card style={{ width: "100%" }}>
          <CardContent>
            <Typography color="black" align="center">
              Send Notification Here
            </Typography>
            <br />
            <TextField
              style={{ width: "100%" }}
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              variant="filled"
              label="Title "
              required
            />
            <br />
            <br />

            {/* <CKEditor
              //   style={{ height: "100px" }}
              editor={ClassicEditor}
              data="Enter your message here !"
              label="message"
              onReady={(editor) => {
                // console.log("editor ready to use", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setMessage(data);
                console.log(data);
              }}
            /> */}
            <TextField
              style={{ width: "100%" }}
              type="text"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              variant="filled"
              label="Message "
              required
            />

            <br />
            <br />
            <TextField
              name="Image"
              style={{ width: "100%" }}
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              variant="filled"
              required
            />
            <br />
            <br />
            <select
              id="demo-simple-select"
              style={{
                width: "100%",
                fontSize: "18px",
                color: "#2E2E2E",
                backgroundColor: "#f0f0f0",
                padding: "10px",
              }}
              onChange={(e) => {
                let selected = e.target.value;
                setSelectedItem(selected);
              }}
            >
              <option value={"1"}>Select Target Screen</option>
              {targetArray.map((d, i) => {
                return <option value={d.target_screen}>{d.name}</option>;
              })}
            </select>
            <br />

            <div style={{ margin: 20 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Send
              </Button>
            </div>
          </CardContent>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default AddNotification;
