import axios from "axios";

const BASE_URL = "https://mathapi.almastarts.com/api/";
// const BASE_URL = "http://localhost:2800/api/";

let token = "";
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: localStorage.getItem("accesstoken"),
  },
});

export default api;

export const image_url = "https://mathapi.almastarts.com/";
// export const image_url = "http://localhost:2800/";
