import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  InputLabel,
  Button,
  Typography,
  Container,
  TextField,
} from "@mui/material";
import { CardMedia, MenuItem } from "@material-ui/core";
import Select from "@mui/material/Select";
import api from "../../utils/API";
import { height } from "@mui/system";
import { image_url } from "../../utils/API";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AppLogo = () => {
  const history = useHistory();

  const [logo_url, setlogo_url] = useState();
  const [logo_id, setlogo_id] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState([]);
  const [refresh, setrefresh] = useState(1);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("message");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("accesstoken")) {
      history.push("/");
    }
  }, []);

  useEffect(async () => {
    const res = await api.post("/getLogo");
    console.log(res.data.data);
    setlogo_url(res.data.data[0].logo_url);
    setlogo_id(res.data.data[0]._id);
    setData(res.data.data);
  }, [refresh]);

  let newdata = [];
  data.map((data, index) => {
    newdata.push({ sn: index + 1, ...data });
  });

  const handleSubmit = async () => {
    const formD = new FormData();
    formD.append("logo_url", selectedFile);
    formD.append("logo_id", logo_id);
    console.log("id", logo_id);
    if (selectedFile == null) {
      return (
        setType("error"), setMessage("select logo to update"), setOpen(true)
      );
    }
    const res = await api.post("/updateLogo", formD);
    console.log(res.data.message);
    if (res.data.status == 1) {
      setType("success");
      setMessage(res.data.message);
      setOpen(true);
    } else {
      setType("error");
      setMessage(res.data.message);
      setOpen(true);
    }
    // document.getElementById("1").value = "";
    // setrefresh("2");
    if (window) {
      window.location.reload();
    }
  };

  const columns = [
    { field: "sn", headerName: "SN#", width: 120 },
    // { field: "logo_url", headerName: 'Logo URL', width: 220 },
    {
      field: "logo_url",
      headerName: "Logo URL",
      width: 220,
      renderCell: (cellValues) => {
        return (
          <div>
            <img
              src={`${image_url}${cellValues.row.logo_url}`}
              height={60}
              width={60}
            />
          </div>
        );
      },
    },
    // { field: "logo_url", headerName: 'Logo URL', width: 220 },
    // {
    // field: "Action", width: 290,
    // renderCell: (cellValues) => {
    //   return (
    //     <div style={{display:"flex", flexDirection:'row'}}>
    //       <div >
    //        <Button
    //       variant="contained"
    //       color="error"
    //       onClick={(event) => {
    //         handleEdit(event, cellValues);
    //       }}
    //     >
    //       EDIT
    //     </Button></div>
    //     {/* <div style={{marginLeft:10}}>
    //     <Button
    //       variant="contained"
    //       color="error"
    //       onClick={(event) => {
    //         handleDelete(event, cellValues);
    //       }}
    //     >
    //       DELETE
    //     </Button> </div> */}
    //     </div>

    //   );
    // }
    // }
  ];

  return (
    <Wrapper>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Card style={{ width: "60%" }}>
          <Typography variant="h4" align="center" color="secondary">
            Add Logo Here!
          </Typography>
          <CardContent>
            <Container>
              <Box
                style={{ textAlign: "center" }}
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                {/* <TextField style={{width:"70%"}} id= "1" value= {banner_name} onChange ={(e)=>setBanner_name(e.target.value)} required label="Banner Name" variant="filled" /> */}
                <TextField
                  style={{ width: "70%" }}
                  type="file"
                  id="2"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  required
                  variant="filled"
                />
                <br />{" "}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </Box>
            </Container>
          </CardContent>
        </Card>

        <Card style={{ width: "38%" }}>
          <Typography variant="h4" align="center" color="secondary" padding={1}>
            Logo Preview Here!
          </Typography>
          <CardMedia>
            <img
              src={`${image_url}${logo_url}`}
              height={"200px"}
              width={"410px"}
            />
          </CardMedia>
        </Card>
      </div>
      <br />
    </Wrapper>
  );
};

export default AppLogo;
