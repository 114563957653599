import React from 'react';
import { Wrapper } from '../../components';

function WebsiteSetting() {
  return (
    <Wrapper>
    <p>AddContest</p>
  </Wrapper>
  )
}

export default WebsiteSetting